import React, { useEffect, useState } from "react";
import Select from 'react-select';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import DotColor from "../../components/DotColor";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputColor from "../../components/InputColor";
import InputFile from "../../components/InputFile";
import { renderError } from "../../helpers/errors";
import api from "../../services/api";
import { toast } from "react-toastify";
import { splitArray } from "../../helpers/conversions";
import { Loading } from "../../components/Loading";
import { createOptions } from "../../helpers/selects";
import { ModalDelete } from "../../components/ModalDelete";

const types = [
    {label: 'Imagem', value: 'image'},
    {label: 'Cor', value: 'hexadecimal'}
];


export const ColorList = () => {
    const [loading, setLoading] = useState(false);

    const [filters, setFilters] = useState('');
    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [showEditGroup, setShowEditGroup] = useState(false);
    const [showCreateColor, setShowCreateColor] = useState(false);
    const [showEditColor, setShowEditColor] = useState(false);

    const [groups, setGroups] = useState([]);

    const [groupToDelete, setGroupToDelete] = useState(null);
    const [colorToDelete, setColorToDelete] = useState(null);

    const [groupId, setGroupId] = useState(null);
    const [groupName, setGroupName] = useState('');
    const [groupHexadecimal, setGroupHexadecimal] = useState('');
    const [groupType, setGroupType] = useState('');

    const [groupImageBase64, setGroupImageBase64] = useState('');
    const [groupImageName, setGroupImageName] = useState('');

    const [colorId, setColorId] = useState(null);
    const [colorGroupId, setColorGroupId] = useState(null);
    const [colorName, setColorName] = useState('');
    const [colorHexadecimal, setColorHexadecimal] = useState('');
    const [colorSku, setColorSku] = useState('');
    const [colorType, setColorType] = useState('');

    const [colorImageBase64, setColorImageBase64] = useState('');
    const [colorImageName, setColorImageName] = useState('');

    useEffect(() => {
        getGroups();
    }, [filters]);

    useEffect(() => {
        if(!showEditGroup) cleanGroup();
    }, [showEditGroup]);

    useEffect(() => {
        if(!showCreateGroup) cleanGroup();
    }, [showCreateGroup]);

    useEffect(() => {
        if(!showEditColor) cleanColor();
    }, [showEditColor]);

    useEffect(() => {
        if(!showCreateColor) cleanColor();
    }, [showCreateColor]);

    const cleanGroup = () => {
        setGroupId(null);
        setGroupName('');
        setGroupHexadecimal('');
        setGroupImageName('');
        setGroupType('');
    }

    const cleanColor = () => {
        setColorId(null);
        setColorGroupId(null);
        setColorName('');
        setColorHexadecimal('');
        setColorSku('');
        setColorImageName('');
        setColorType('');
    }

    const getGroups = () => {
        setGroups([]);
        setLoading(true);
        
        api.get(`/group-color${filters}`).then(res => {
            setGroups(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const storeGroup = () => {
        setLoading(true);

        let data = {
            name: groupName,
            type: groupType
        };

        if(groupType === 'hexadecimal'){
            data.hexadecimal = groupHexadecimal;
        }
        if(groupType === 'image'){
            data.image = groupImageBase64;
        }

        api.post(`/group-color`, data).then(res => {
            toast.success('Grupo de cores cadastrado com sucesso');
            setShowCreateGroup(false);
            getGroups();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateGroup = () => {
        setLoading(true);

        const data = {
            name: groupName,
            type: groupType,
            hexadecimal: groupHexadecimal,
        };

        if(groupType === 'hexadecimal'){
            data.hexadecimal = groupHexadecimal;
        }
        if(groupType === 'image'){
            data.image = groupImageBase64;
        }

        api.put(`/group-color/${groupId}`, data).then(res => {
            toast.success('Grupo de cores atualizado com sucesso');
            setShowEditGroup(false);
            getGroups();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const storeColor = () => {
        setLoading(true);

        let data = {
            name: colorName,
            sku: colorSku,
            group_id: colorGroupId,
            type: colorType
        };

        if(colorType === 'hexadecimal'){
            data.hexadecimal = colorHexadecimal;
        }
        if(colorType === 'image'){
            data.image = colorImageBase64;
        }

        api.post(`/color`, data).then(res => {
            toast.success('Cor cadastrada com sucesso');
            setShowCreateColor(false);
            getGroups();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateColor = () => {
        setLoading(true);

        let data = {
            name: colorName,
            sku: colorSku,
            group_id: colorGroupId,
            type: colorType
        }

        if(colorType === 'hexadecimal'){
            data.hexadecimal = colorHexadecimal;
        }
        if(colorType === 'image'){
            data.image = colorImageBase64;
        }

        api.put(`/color/${colorId}`, data).then(res => {
            toast.success('Cor atualizada com sucesso');
            setShowEditColor(false);
            getGroups();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editGroup = group => {
        setGroupId(group.id);
        setGroupName(group.name);
        setGroupHexadecimal(group.hexadecimal);
        setGroupType(group.type);
        setGroupImageName(group.image);
        setShowEditGroup(true);
    }

    const createColor = group => {
        setColorGroupId(group.id);
        setColorHexadecimal(group.hexadecimal);
        setShowCreateColor(true);
    }

    const editColor = color => {
        setColorId(color.id);
        setColorName(color.name);
        setColorHexadecimal(color.hexadecimal);
        setColorSku(color.sku);
        setColorGroupId(color.group_id);
        setColorType(color.type);
        setColorImageName(color.image);
        setShowEditColor(true);
    }

    const deleteGroup = () => {
        setLoading(true);
        
        api.delete(`/group-color/${groupToDelete}`).then(res => {
            setGroupToDelete(null);
            getGroups();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteColor = () => {
        setLoading(true);
        
        api.delete(`/color/${colorToDelete}`).then(res => {
            setColorToDelete(null);
            getGroups();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const Group = ({group}) => {
        const [active, setActive] = useState(false);
    
        return (
            <li className={active ? 'active' : ''}>
                <div className="group">
                    <button onClick={() => setActive(prev => !prev)}>
                        <DotColor size={20} color={group.hexadecimal} image={group.image_url?.small} type={group.type} />
                        <span>{group.name}</span>
                    </button>
                    <button onClick={() => createColor(group)}><img src={ require(`../../assets/images/svgs/plus-circle.svg`).default} /></button>
                    <button onClick={() => editGroup(group)}><img src={ require(`../../assets/images/svgs/edit.svg`).default} /></button>
                    <button onClick={() => setGroupToDelete(group.id)}><img src={ require(`../../assets/images/svgs/delete-small.svg`).default} /></button>
                    <button onClick={() => setActive(prev => !prev)}><img className="arrow" src={ require(`../../assets/images/svgs/arrow-gray.svg`).default} /></button>
                </div>
                {group.colors &&
                    <ul>
                        {group.colors.map((color, index) => (
                            <Color key={index} color={color} theme={index % 2 === 0 ? 'dark' : 'light'} />
                        ))}
                    </ul>
                }
            </li>
        )
    }
    
    const Color = ({color, theme}) => {
        return (
            <li className={`color ${theme}`}>
                <div className="color-desc">
                    <DotColor size={20} color={color.hexadecimal} image={color.image_url?.small} type={color.type} />
                    <span>{color.name}</span>
                </div>
                <div className="color-sku">
                    <span>{color.sku}</span>
                </div>
                <button onClick={() => editColor(color)}><img src={ require(`../../assets/images/svgs/edit.svg`).default} /></button>
                <button onClick={() => setColorToDelete(color.id)}><img src={ require(`../../assets/images/svgs/delete-small.svg`).default} /></button>
            </li>
        );
    }

    return (
        <>
            <ModalDelete 
                show={colorToDelete !== null}
                action={deleteColor}
                close={() => setColorToDelete(null)}
                loading={loading}
            />
            <ModalDelete
                show={groupToDelete !== null}
                action={deleteGroup}
                close={() => setGroupToDelete(null)}
                loading={loading}
            />
            <Modal
                title={`Cadastrar grupo`}
                show={showCreateGroup}
                close={() => setShowCreateGroup(false)}
                footer={
                    <Button 
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeGroup}
                        loading={loading}
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Nome`} change={setGroupName} value={groupName} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Tipo`} value={groupType} change={setGroupType} options={types} />
                        </div>
                    </div>
                    <div className="row">
                        {groupType ===  'hexadecimal' &&
                            <div className="col-6">
                                <InputColor label={`Cor do grupo`} value={groupHexadecimal} change={setGroupHexadecimal} />
                            </div>
                        }
                        {groupType ===  'image' &&
                            <div className="col-6">
                                <InputFile 
                                    label={`Imagem`} 
                                    base64={groupImageBase64}
                                    changeBase64={setGroupImageBase64}
                                    name={groupImageName}
                                    changeName={setGroupImageName}
                                    description={`Tamanho ideal: 80px por 80px`}
                                />
                            </div>
                        }
                    </div>                    
                </div>
            </Modal>
            <Modal
                title={`Editar grupo`}
                show={showEditGroup}
                close={() => setShowEditGroup(false)}
                footer={
                    <Button 
                        type={`primary`}
                        size={`small`}
                        text={`Salvar alterações`}
                        svg={`save-white`}
                        action={updateGroup}
                        loading={loading}
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Nome`} change={setGroupName} value={groupName} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Tipo`} value={groupType} change={setGroupType} options={types} />
                        </div>
                    </div>
                    <div className="row">
                        {groupType ===  'hexadecimal' &&
                            <div className="col-6">
                                <InputColor label={`Cor do grupo`} value={groupHexadecimal} change={setGroupHexadecimal} />
                            </div>
                        }
                        {groupType ===  'image' &&
                            <div className="col-6">
                                <InputFile 
                                    label={`Imagem`} 
                                    base64={groupImageBase64}
                                    changeBase64={setGroupImageBase64}
                                    name={groupImageName}
                                    changeName={setGroupImageName}
                                    description={`Tamanho ideal: 80px por 80px`}
                                />
                            </div>
                        }
                    </div>                    
                </div>
            </Modal>
            <Modal
                title={`Cadastrar cor`}
                show={showCreateColor}
                close={() => setShowCreateColor(false)}
                footer={
                    <Button 
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeColor}
                        loading={loading}
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Nome`} change={setColorName} value={colorName} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Tipo`} value={colorType} change={setColorType} options={types} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            {colorType === 'hexadecimal' &&
                                <InputColor label={`Cor`} value={colorHexadecimal} change={setColorHexadecimal} />
                            }
                            {colorType === 'image' &&
                                <InputFile 
                                    label={`Imagem`} 
                                    base64={colorImageBase64}
                                    changeBase64={setColorImageBase64}
                                    name={colorImageName}
                                    changeName={setColorImageName}
                                    description={`Tamanho ideal: 80px por 80px`}
                                />
                            }
                        </div>
                    </div>  
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`SKU`} change={setColorSku} value={colorSku} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Grupo`} value={colorGroupId} change={setColorGroupId} options={createOptions(groups, 'name', 'id')} />
                        </div>
                    </div>                   
                </div>
            </Modal>
            <Modal
                title={`Editar cor`}
                show={showEditColor}
                close={() => setShowEditColor(false)}
                footer={
                    <Button 
                        type={`primary`}
                        size={`small`}
                        text={`Salvar alterações`}
                        svg={`save-white`}
                        action={updateColor}
                        loading={loading}
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Nome`} change={setColorName} value={colorName} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Tipo`} value={colorType} change={setColorType} options={types} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            {colorType === 'hexadecimal' &&
                                <InputColor label={`Cor`} value={colorHexadecimal} change={setColorHexadecimal} />
                            }
                            {colorType === 'image' &&
                                <InputFile 
                                    label={`Imagem`} 
                                    base64={colorImageBase64}
                                    changeBase64={setColorImageBase64}
                                    name={colorImageName}
                                    changeName={setColorImageName}
                                    description={`Tamanho ideal: 80px por 80px`}
                                />
                            }
                        </div>
                    </div>  
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`SKU`} change={setColorSku} value={colorSku} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Grupo`} value={colorGroupId} change={setColorGroupId} options={createOptions(groups, 'name', 'id')} />
                        </div>
                    </div>              
                </div>
            </Modal>
            <PanelTemplate id="color-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome', key: 'name', type: 'text' }
                            ]}
                            filter={setFilters}
                        />
                    }

                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar grupo`} svg="plus-white" action={() => setShowCreateGroup(true)} />
                        </>
                    }
                />
                <Loading show={loading} />
                <div className="row">
                    <div className="col-4">
                        <div className="groups">
                            <ul>
                                {splitArray(groups, 3, 0).map((group, index) => (
                                    <Group key={index} group={group} />
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="groups">
                            <ul>
                                {splitArray(groups, 3, 1).map((group, index) => (
                                    <Group key={index} group={group} />
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="groups">
                            <ul>
                                {splitArray(groups, 3, 2).map((group, index) => (
                                    <Group key={index} group={group} />
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            
            </PanelTemplate>
        </>
    );
}

export default ColorList;