const origins = [
    {label: "Selecione...", value: ""},
    {label: "Bríndice", value: "Bríndice"},
    {label: "Freeshop", value: "Freeshop"},
    {label: "Prospecção", value: "Prospecção"},
    {label: "Google", value: "Google"},
    {label: "Facebook", value: "Facebook"},
    {label: "Instagram", value: "Instagram"},
    {label: "Linkedin", value: "Linkedin"},
    {label: "Outros", value: "Outros"}
];

export default origins;