export const calculateCustomizationTotal = (quantity, cost, calculationPer) => {
    let thousands = quantity / 1000;
    thousands = Math.ceil(thousands);

    let total = cost * quantity;

    if(calculationPer === 'thousand'){
        total = cost * thousands;
    }

    return total;
}