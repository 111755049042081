import React, { useState } from "react";
import Button from "../../Button";
import Input from "../../Input";
import Modal from "../../Modal";

export const Filter = () => {
    const [show, setShow] = useState(false);
    
    return (
        <>
            <Modal show={show} close={() => setShow(false)} title="Período" footer={
                <>
                    <Button text="Limpar filtros" type="secondary" size="small" action={() => alert('ok')}/>
                    <Button text="Filtrar" size="small" action={() => alert('ok')}/>
                </>
            }>
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input label="Data inicial" />
                        </div>
                        <div className="col-6">
                            <Input label="Data final"/>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* <div className="filter">
                <span>Resultados de 01/11/2021 a 20/11/2021</span>
                <Button type="secondary" size="medium" text={`Mudar período`} svg="calendar" action={() => setShow(true)} />
            </div> */}
        </>
    );
}