import React, { useEffect, useState } from "react";
import Select from 'react-select';
import moment from 'moment';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import api from "../../services/api";
import { toast } from "react-toastify";
import { renderError } from "../../helpers/errors";
import axios from "axios";
import InputRadio from "../../components/InputRadio";
import origins from "../../seeds/origins";
import { createOptions } from "../../helpers/selects";


export const CustomerList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');

    const [customers, setCustomers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreateCustomer, setShowCreateCustomer] = useState(false);

    const [customerName, setCustomerName] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerCompanies, setCustomerCampanies] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        getCompanies();
        getCustomers();
    }, [filters]);

    useEffect(() => {
        if(!showCreateCustomer) clean();
    }, [showCreateCustomer]);

    useEffect(() => {
        generateData();
    }, [customers]);

    const generateData = () => {
        const header = ['Código', 'Nome', 'E-mail', 'Telefone', 'Empresa', 'Cadastrado em'];
        const rows = [];

        customers.map(customer => {
            const countCompanies = customer.companies.length;
            const textCount = countCompanies > 1 ? ` +${countCompanies-1}` : '';

            rows.push({
                data: [
                    customer.code,
                    customer.name,
                    customer.email,
                    customer.phone,
                    countCompanies > 0 ? `${customer.companies[0].trade_name}${textCount}` : '',
                    customer.created_at ? moment(customer.created_at).format('DD/MM/YYYY HH:mm') : ''
                ],
                to: `/customer/edit/${customer.id}`
            })
        });

        setData({header, rows});
    }

    const getCompanies = () => {
        api.get(`/company`).then(res => {
            setCompanies(res.data.resources);
        });
    }

    const getCustomers = () => {
        setCustomers([]);
        setLoading(true);

        if (typeof cancelToken != typeof undefined) {
            window.cancelToken.cancel()
        }
      
        window.cancelToken = axios.CancelToken.source();

        api.get(`/customer${filters}`, {
            cancelToken: window.cancelToken.token
        }).then(res => {
            setCustomers(res.data.resources);
        }).catch(error => {
            
        }).then(() => setLoading(false));
    }

    const storeCustomer = () => {
        setLoading(true);

        api.post(`/customer`, {
            name: customerName,
            email: customerEmail,
            phone: customerPhone,
            company_ids: customerCompanies
        }).then(res => {
            toast.success('Pessoa cadastrada com sucesso');
            setShowCreateCustomer(false);
            getCustomers();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setCustomerName('');
        setCustomerEmail('');
        setCustomerPhone('');
        setCustomerCampanies([]);
    }

    return (
        <>
            <Modal
                title={`Cadastrar pessoa`}
                show={showCreateCustomer}
                close={() => setShowCreateCustomer(false)}
                footer={
                    <Button 
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeCustomer}
                        loading={loading}
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col">
                            <Input type={`text`} label={`Nome`} change={setCustomerName} value={customerName}/>
                        </div>
                    </div>   
                    <div className="row">
                        <div className="col">
                            <Input type={`text`} label={`E-mail`} change={setCustomerEmail} value={customerEmail} />
                        </div>
                    </div>   
                    <div className="row">
                        <div className="col">
                            <Input type={`text`} label={`Telefone`} change={setCustomerPhone} value={customerPhone} mask={`convertToPhone`} />
                        </div>
                    </div>   
                    <div className="row">
                        <div className="col">
                            <InputSelect type={`text`} label={`Empresa`} isMulti={true} options={createOptions(companies, 'trade_name', 'id')} change={setCustomerCampanies} value={customerCompanies} />
                        </div>
                    </div>      
                </div>
            </Modal>
            <PanelTemplate id="customer-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Código', key: 'code', type: 'text' },
                                {name: 'Nome', key: 'name', type: 'text' },
                                {name: 'E-mail', key: 'email', type: 'text' }
                            ]}
                            filter={setFilters}
                        />
                    }

                    right={
                        <>
                            <Button type={`secondary`} size={`small`} text={`Exportar pessoas`} action={() => window.open(`${process.env.REACT_APP_URL}/customer/export`)}  />
                            <Button type={`primary`} size={`small`} text={`Cadastrar pessoa`} svg="plus-white" action={() => setShowCreateCustomer(true)}  />
                        </>
                    }
                />
                
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default CustomerList;