import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PanelTemplate from "../../templates/Panel";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";

import { ModalDelete } from "../../components/ModalDelete";
import BoxTabs from "../../components/BoxTabs";
import OrderDetailTab from "./tabs/detail";
import OrderNfsTab from "./tabs/nfs";
import BankSlipTab from "./tabs/bankslips";

export const OrderEdit = () => {
    const { id } = useParams();
    const navigete = useNavigate();

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        getOrder();
    }, []);

    const getOrder = () => {
        setLoading(true);

        api.get(`/order/${id}`).then(res => {
            let resource = res.data.resource;
            setOrder(resource);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const deleteOrder = () => {
        setLoading(true);

        api.delete(`/order/${id}`).then(res => {
            navigete(`/order/list`);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <ModalDelete
                show={confirmDelete}
                action={deleteOrder}
                loading={loading}
                close={() => setConfirmDelete(false)}
            />
            <PanelTemplate id="order-edit">
                <BoxTabs
                    titles={[
                        'Detalhes',
                        'Notas fiscais',
                        'Boletos'
                    ]}
                    contents={
                        [
                            <OrderDetailTab
                                order={order}
                            />,
                            <OrderNfsTab
                                order={order}
                            />,
                            <BankSlipTab
                                order={order}
                            />
                        ]
                    }
                />
                <PageFooter spaceBetween={true}>
                    <div>
                        <Button type={`primary`} size={`small`} svg={`pdf`} text={`Visualizar pedido`} action={() => window.open(order?.pdf_url)} loading={loading} />
                    </div>
                    <div>
                        <Button type={`secondary`} size={`small`} loading={loading} text={`Excluir`} svg={`delete-small`} action={() => setConfirmDelete(true)} />
                    </div>
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default OrderEdit;