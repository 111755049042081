import React, { useEffect, useState } from "react";
import Select from 'react-select';
import moment from 'moment';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { toast } from "react-toastify";

const types = [
    {label: 'Milheiro', value: 'thousand'},
    {label: 'Unidade', value: 'unit'}
];

const multiplyColorsOptions = [
    {label: 'Não', value: false},
    {label: 'Sim', value: true}
];

export const CustomizationList = () => {
    const [loading, setLoading] = useState(false);

    const [filters, setFilters] = useState('');

    const [customizations, setCustomizations] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreateCustomization, setShowCreateCustomization] = useState(false);

    const [customizationName, setCustomizationName] =  useState('');
    const [customizationCalculationPer, setCustomizationCalculationPer] = useState(null);
    const [customizationMultiplyColors, setCustomizationMultiplyColors] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        getCustomizations();
    }, [filters]);

    useEffect(() => {
        generateData();
    }, [customizations]);

    useEffect(() => {
        if(!showCreateCustomization) clean();
    }, [showCreateCustomization]);

    const getCustomizations = () => {
        setCustomizations([]);
        setLoading(true);

        api.get(`/customization${filters}`).then(res => {
            setCustomizations(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Nome', 'Cálculo por', 'Última atualização', 'Cadastro'];
        const rows = [];

        customizations.map(customization => {
            rows.push({
                data: [
                    customization.name,
                    customization.calculation_per === 'thousand' ? 'Milheiro' : 'Unidade' ,
                    moment(customization.updated_at).format('DD/MM/YYYY HH:mm'),
                    moment(customization.created_at).format('DD/MM/YYYY HH:mm'),
                ],
                to: `/customization/edit/${customization.id}`
            })
        });

        setData({header, rows});
    }

    const storeCustomization = () => {
        setLoading(true);

        api.post(`/customization`, {
            name: customizationName,
            calculation_per: customizationCalculationPer,
            multiply_colors: customizationMultiplyColors
        }).then(res => {
            toast.success('Gravação cadastrada com sucesso');
            setShowCreateCustomization(false);
            getCustomizations();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setCustomizationName('');
        setCustomizationCalculationPer(null);
    }

    return (
        <>
            <Modal
                title={`Cadastrar gravação`}
                size={`big`}
                show={showCreateCustomization}
                close={() => setShowCreateCustomization(false)}
                footer={
                    <Button
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeCustomization}
                        loading={loading} 
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-4">
                            <Input type={`text`} label={`Nome *`} value={customizationName} change={setCustomizationName} />
                        </div>
                        <div className="col-4">
                            <InputSelect label={`Cálculo por *`} value={customizationCalculationPer} change={setCustomizationCalculationPer} options={types} />
                        </div>
                        <div className="col-4">
                            <InputSelect label={`Múltiplicar pelas cores *`} value={customizationMultiplyColors} change={setCustomizationMultiplyColors} options={multiplyColorsOptions} />
                        </div>
                    </div>
                </div>
            </Modal>
            <PanelTemplate id="customization-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome', key: 'name', type: 'text' }
                            ]}
                            filter={setFilters}
                        />
                    }

                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar gravação`} svg="plus-white" action={() => setShowCreateCustomization(true)}  />
                        </>
                    }
                />
                
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading}/>
                </Box>
            </PanelTemplate>
        </>
    );
}

export default CustomizationList;