import React, { useState, useEffect } from "react";

import PanelTemplate from "../../templates/Panel";
import DotStatus from "../../components/DotStatus";
import BoxTabs from "../../components/BoxTabs";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import InputRadio from "../../components/InputRadio";
import InputEditor from "../../components/InputEditor";
import InputCheckboxes from "../../components/InputCheckboxes";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import InputColors from "../../components/InputColors";
import PageHeader from "../../components/PageHeader";
import InputTextarea from "../../components/InputTextarea";
import InputCheckboxesSublevels from "../../components/InputCheckboxesSublevels";
import api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { createOptions } from "../../helpers/selects";
import { renderError } from "../../helpers/errors";
import { toast } from "react-toastify";
import { getBase64, getBase64Promise } from "../../helpers/conversions";
import { ModalDelete } from "../../components/ModalDelete";
import Modal from "../../components/Modal";
import RLDD from 'react-list-drag-and-drop/lib/RLDD';


const statusOptions = [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false }
];

const featuredOptions = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false }
];

const ProductDetailsTab = ({
    loading, 
    status, 
    setStatus, 
    sku, 
    setSku, 
    featured, 
    setFeatured, 
    recent, 
    setRecent, 
    name, 
    setName, 
    categories, 
    setSelectedCategories, 
    selectedCategories, 
    description, 
    setDescription, 
    specification, 
    setSpecification, 
    tags, 
    tag, 
    setTag, 
    minQuantity, 
    setMinQuantity
}) => {
    return (
        <div>
            {!loading &&
            <>
                <div className="row">
                    <div className="col-6">
                        <h2 className="section-title">Informações gerais</h2>
                        <div className="row">
                            <div className="col-6">
                                <InputSelect label={`Status`} options={statusOptions} value={status} change={setStatus} />
                            </div>
                            <div className="col-6">
                                <Input label={`Código SKU`} value={sku} change={setSku} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <InputSelect label={`Destaque`} options={featuredOptions} value={featured} change={setFeatured} />
                            </div>
                        </div>

                        <Input label={`Nome do produto`} value={name} change={setName} />

                    </div>
                    <div className="col-6">
                        <h2 className="section-title">Descrição do produto</h2>
                        <InputTextarea
                            value={description}
                            change={setDescription}
                            rows={12}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <h2 className="section-title">Especificações</h2>
                        <InputEditor value={specification} change={setSpecification} />
                    </div>
                    <div className="col-6">
                        <h2 className="section-title">Categorias</h2>
                        <InputCheckboxesSublevels
                            options={categories}
                            selecteds={selectedCategories}
                            setSelecteds={setSelectedCategories}
                        />
                    </div>
                </div>
            </>
            }
        </div>
    );
}

const ImagesTab = ({product}) => {
    const [loading, setLoading] = useState(false);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [images, setImages] = useState(product.images);
    const [imageToDelete, setImageToDelete] = useState(null);

    const chooseImage = () => {
        var input = document.createElement('input');
        input.type = 'file';
        // input.multiple = true;

        input.onchange = e => { 
            let toUploadedImages = [];
            let files = e.target.files;
            for (let i = 0; i < files.length; i++) {
                toUploadedImages.push(files.item(i));
            }
            setUploadedImages(toUploadedImages);
        }

        input.click();
    }

    useEffect(() => {
        if(uploadedImages.length === 0) return;

        let promises = [];
        uploadedImages.map((uploadedImage, index) => {
            promises.push(getBase64Promise(uploadedImage));
        });
        Promise.all(promises).then(bases64 => {
            uploadImages(bases64);
        });
    }, [uploadedImages]);    

    const uploadImages = bases64 => {
        api.post(`/product/image`, {
            product_id: product.id,
            images: bases64
        }).then(res => {
            setImages(res.data.resource.images);
        }).catch(error => {
            renderError(error);
        }).then(() => setUploadedImages([]));
    }

    const deleteImage = () => {
        setLoading(true);
        api.delete(`/product/image/${imageToDelete}`).then(res => {
            toast.success('Imagem deletada com sucesso');
            setImages(prev => prev.filter(image => image.id !== imageToDelete));
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setImageToDelete(null);
            setLoading(false)
        });
    }

    const idStringToInt = () => {
        let resources = [];
        images.map((image, index) => {
            resources.push({...image, id: index});
        })
        return resources;
    }

    return (
        <>
            <ModalDelete 
                show={imageToDelete !== null}
                action={deleteImage}
                close={() => setImageToDelete(null)}
                loading={loading}
            />
            <div className="product-images">
                <button onClick={() => chooseImage()}>
                    <img src={ require(`../../assets/images/svgs/upload.svg`).default} />
                    <span>Enviar imagem</span>
                    <span><small>1.200px por 1.200px</small></span>
                </button>
                {/* <RLDD
                    items={idStringToInt(images)}
                    itemRenderer={(image) => {
                        return (
                            <div>
                                <img src={image.image_url.thumbnail} />
                                <Button type={`transparent`} size={`small`} svg={`delete`} action={() => setImageToDelete(image.id)} />
                            </div> 
                        );
                    }}
                    onChange={e => console.log(e)}
                /> */}
                {images.map((image, index) => (
                    <div>
                        <img src={image.image_url.thumbnail} />
                        <Button type={`transparent`} size={`small`} svg={`delete`} action={() => setImageToDelete(image.id)} />
                    </div> 
                ))}
                {/* {uploadedImages.map((image, index) => (
                    <div key={index}>
                        <img key={index} src={require(`../../assets/images/svgs/loading.svg`).default } />
                    </div>
                ))} */}
            </div>
        </>
    );
}

const KeyWordsTab = ({product, keywords, setKeywords}) => {
    const [showCreate, setShowCreate] = useState(false);
    const [selecteds, setSelecteds] = useState([]);
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        setKeywords(product.keywords);
    }, [product]);

    useEffect(() => {
        if(!showCreate) clean();
    }, [showCreate]);

    const deleteKeywords = () => {
        let toKeywords = keywords.filter(k => !selecteds.includes(k));
        setKeywords(toKeywords);
    }

    const storeKeyword = () => {
        if(keywords.includes(keyword)){
            toast.info('Palavra-chave já cadastrada');
        } else {
            let toKeywords = [...keywords];
            toKeywords.push(keyword.trim());
            toKeywords.sort((a, b) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });
            setKeywords(toKeywords);
        }
        setShowCreate(false);
    }

    const clean = () => {
        setKeyword('');
        setSelecteds([]);
    }
    
    return (
        <>
            <Modal
                show={showCreate}
                title={`Cadastrar palavra-chave`}
                close={() => setShowCreate(false)}
                footer={
                    <Button
                        type={`primary`}
                        size={`small`}
                        svg={`save-white`}
                        text={`Cadastrar`}
                        action={storeKeyword}
                    />  
                }
            >
                <div className="section">
                    <Input 
                        type={`text`} 
                        label={`Palavra-chave`} 
                        value={keyword} 
                        change={setKeyword}
                        action={storeKeyword}
                    />
                </div>

            </Modal>
            <div className="row">
                <div className="col-6">
                    <PageHeader
                        left={
                            <h2 className="section-title">Palavras-chave</h2>
                        }
                        right={
                            <>
                                <Button type={`primary`} size={`small`} svg={`plus-white`} text={`Cadastrar`} action={() => setShowCreate(true)} /> 
                                <Button type={`secondary`} size={`small`} svg={`delete-small`} text={`Excluir selecionadas`} action={deleteKeywords} />
                            </>
                        }
                    />
                    <InputCheckboxes 
                        options={createOptions(keywords)}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                    />
                </div>
            </div>
        </>
    );
}

const SeoTab = ({metaTitle, setMetaTitle, metaDescription, setMetaDescription, slug, setSlug}) => {
    return (
        <div className="row">
            <div className="col-6">
            <Input label={`Slug`} value={slug} change={setSlug} mask={`convertToSlug`} />
            <Input label={`Meta title`} value={metaTitle} change={setMetaTitle} />
            <InputTextarea label={`Meta description`} value={metaDescription} change={setMetaDescription} rows={5}/>
            </div>
        </div>
    );
}

export const ProductEdit = () => {
    const { id } = useParams();
    const navigete = useNavigate();

    const [loading, setLoading] = useState(true);

    const [tags, setTags] = useState([]);

    const [product, setProduct] = useState(null);
    const [status, setStatus] = useState(false);
    const [sku, setSku] = useState('');
    const [featured, setFeatured] = useState(false);
    const [recent, setRecent] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [specification, setSpecification] = useState('');
    const [tag, setTag] = useState(null);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [slug, setSlug] = useState('');
    const [minQuantity, setMinQuantity] = useState(1);

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [variations, setVariations] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [selectedCustomizations, setSelectedCustomizations] = useState([]);

    const [confirmDelete, setConfirmDelete] = useState(false);


    useEffect(() => {
        getCategories();
        getProduct();
    }, []);

    const getProduct = () => {
        setLoading(true);
        api.get(`/product/${id}`).then(res => {
            let resource = res.data.resource;
            console.log(resource);
            let toSelectedCategories = [];
            resource.categories.map(category => toSelectedCategories.push(category.id));
            setProduct(resource);
            setSelectedCategories(toSelectedCategories);
            setStatus(resource.status);
            setSku(resource.sku);
            setFeatured(resource.featured);
            setName(resource.name);
            setDescription(resource.description);
            setSpecification(resource.specification);
            setMetaTitle(resource.meta_title ?? "");
            setMetaDescription(resource.meta_description ?? "");
            setSlug(resource.slug);

        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getCategories = () => {
        api.get(`/category`).then(res => {
            let toCategories = [];

            res.data.resources.map(category => {
                let options = [];

                category.categories.map(subcategory => {
                    options.push({
                        label: subcategory.name,
                        value: subcategory.id
                    })
                });
                toCategories.push({
                    label: category.name,
                    value: category.id,
                    options
                })
            })
            setCategories(toCategories);
        });
    }

    const updateProduct = () => {
        api.put(`/product/${id}`, {
            status,
            featured,
            recent,
            name,
            sku,
            description,
            specification: specification,
            categories: selectedCategories,
            keywords,
            customizations: selectedCustomizations,
            tag_id: tag,
            subproducts: variations,
            meta_title: metaTitle,
            meta_description: metaDescription,
            slug,
            min_quantity: minQuantity
        }).then(res => {
            toast.success('Produto atualizado com sucesso');
            getProduct();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteProduct = () => {
        setLoading(true);

        api.delete(`/product/${id}`).then(res => {
            navigete(`/product/list`);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <ModalDelete
                show={confirmDelete}
                action={deleteProduct}
                loading={loading}
                close={() => setConfirmDelete(false)}
            />
            <PanelTemplate id="product-edit">
                <BoxTabs
                    titles={[
                        'Detalhes dos produtos',
                        'Imagens',
                        'Palavras-chave',
                        'SEO'
                    ]}
                    contents={[
                        <ProductDetailsTab
                            loading={loading}
                            status={status}
                            setStatus={setStatus}
                            sku={sku}
                            setSku={setSku}
                            featured={featured}
                            setFeatured={setFeatured}
                            recent={recent}
                            setRecent={setRecent}
                            name={name}
                            setName={setName}
                            categories={categories}
                            setSelectedCategories={setSelectedCategories}
                            selectedCategories={selectedCategories}
                            setDescription={setDescription}
                            description={description}
                            setSpecification={setSpecification}
                            specification={specification}
                            tags={tags}
                            tag={tag}
                            setTag={setTag}
                            minQuantity={minQuantity}
                            setMinQuantity={setMinQuantity}
                        />,
                        <ImagesTab
                            product={product}
                        />,
                        <KeyWordsTab
                            product={product}
                            keywords={keywords}
                            setKeywords={setKeywords}
                        />,
                        <SeoTab
                            metaTitle={metaTitle}
                            setMetaTitle={setMetaTitle}
                            metaDescription={metaDescription}
                            setMetaDescription={setMetaDescription}
                            slug={slug}
                            setSlug={setSlug}
                        />,
                    ]}
                />
                <PageFooter spaceBetween={true}>
                    <>
                        <Button
                            type={`primary`}
                            size={`small`}
                            svg={`save-white`}
                            text={`Salvar alterações`}
                            action={updateProduct}
                            loading={loading}
                        />
                        <Button
                            type={`secondary`}
                            size={`small`}
                            svg={`delete-small`}
                            text={`Excluir`}
                            action={() => setConfirmDelete(true)}
                            loading={loading}
                        />
                    </>
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default ProductEdit;