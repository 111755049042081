import React, { useEffect, useState } from "react";
import Select from 'react-select';
import moment from 'moment';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import api from "../../services/api";
import { toast } from "react-toastify";
import { renderError } from "../../helpers/errors";
import axios from "axios";
import InputRadio from "../../components/InputRadio";
import origins from "../../seeds/origins";

const types = [
    {label: 'Fornecedor de gravação', value: 'customization'},
    {label: 'Fornecedor de produto', value: 'product'},
    {label: 'Cliente', value: 'customer'},
    {label: 'Faturamento', value: 'billing'}
];

export const CompanyList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');

    const [companies, setCompanies] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreateCompany, setShowCreateCompany] = useState(false);

    const [companyPfPj, setCompanyPfPj] = useState('pj');

    const [companyTradeName, setCompanyTradeName] = useState('');
    const [companyCompanyName, setCompanyCompanyName] = useState('');
    const [companyCnpj, setCompanyCnpj] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [companyCpf, setCompanyCpf] = useState('');

    const [companyType, setCompanyType] = useState([]);
    const [origin, setOrigin] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        getCompanies();
    }, [filters]);

    useEffect(() => {
        if(!showCreateCompany) clean();
    }, [showCreateCompany]);

    useEffect(() => {
        generateData();
    }, [companies]);

    const generateData = () => {
        const header = ['Código', 'Nome fantasia', 'CNPJ', 'Onde conheceu', 'Responsável', 'Cadastrado em'];
        const rows = [];

        companies.map(company => {
            rows.push({
                data: [
                    company.code,
                    company.trade_name,
                    company.cnpj,
                    company.origin,
                    company?.admin?.name,
                    company.created_at ? moment(company.created_at).format('DD/MM/YYYY HH:mm') : ''
                ],
                to: `/company/edit/${company.id}`
            })
        });

        setData({header, rows});
    }

    const getCompanies = () => {
        setCompanies([]);
        setLoading(true);

        if (typeof cancelToken != typeof undefined) {
            window.cancelToken.cancel()
        }
      
        window.cancelToken = axios.CancelToken.source();

        api.get(`/company${filters}`, {
            cancelToken: window.cancelToken.token
        }).then(res => {
            setCompanies(res.data.resources);
        }).catch(error => {
            
        }).then(() => setLoading(false));
    }

    const storeCompany = () => {
        setLoading(true);

        api.post(`/company`, {
            phone: companyPhone,
            trade_name: companyTradeName,
            company_name: companyCompanyName,
            cnpj: companyCnpj,
            type: companyType,
            origin
        }).then(res => {
            toast.success('Empresa cadastrada com sucesso');
            setShowCreateCompany(false);
            getCompanies();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setCompanyTradeName('');
        setCompanyCompanyName('');
        setCompanyCnpj('');
        setCompanyType([]);
    }

    return (
        <>
            <Modal
                title={`Cadastrar empresa`}
                show={showCreateCompany}
                close={() => setShowCreateCompany(false)}
                footer={
                    <Button 
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeCompany}
                        loading={loading}
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`CNPJ`} change={setCompanyCnpj} value={companyCnpj} mask={`convertToCnpj`} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Input type={`text`} label={`Razão social`} change={setCompanyCompanyName} value={companyCompanyName} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Input type={`text`} label={`Nome fantasia`} change={setCompanyTradeName} value={companyTradeName} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Input type={`text`} label={`Telefone`} change={setCompanyPhone} value={companyPhone} mask={`convertToPhone`} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <InputSelect label={`Tipo`} isMulti={true} options={types} change={setCompanyType} value={companyType} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <InputSelect label={`Onde nos conheceu`} options={origins} change={setOrigin} value={origin} />
                        </div>
                    </div>      
                                 
                </div>
            </Modal>
            <PanelTemplate id="company-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Código', key: 'code', type: 'text' },
                                {name: 'Nome fantasia', key: 'trade_name', type: 'text' },
                                {name: 'Razão social', key: 'company_name', type: 'text' },
                                {name: 'Nome', key: 'name', type: 'text' },
                                {name: 'CPF', key: 'cpf', type: 'text' }
                            ]}
                            filter={setFilters}
                        />
                    }

                    right={
                        <>
                            <Button type={`secondary`} size={`small`} text={`Exportar empresas`} action={() => window.open(`${process.env.REACT_APP_URL}/company/export`)}  />
                            <Button type={`primary`} size={`small`} text={`Cadastrar empresa`} svg="plus-white" action={() => setShowCreateCompany(true)}  />
                        </>
                    }
                />
                
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default CompanyList;