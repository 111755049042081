import React, { useState, useEffect } from "react";

import PanelTemplate from "../../templates/Panel";
import DotStatus from "../../components/DotStatus";
import BoxTabs from "../../components/BoxTabs";
import Modal from "../../components/Modal";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import InputRadio from "../../components/InputRadio";
import InputEditor from "../../components/InputEditor";
import InputCheckboxes from "../../components/InputCheckboxes";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import InputColors from "../../components/InputColors";
import PageHeader from "../../components/PageHeader";
import InputTextarea from "../../components/InputTextarea";
import Table from "../../components/Table";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { Loading } from "../../components/Loading";
import { createOptions } from "../../helpers/selects";
import states from "../../seeds/states";
import axios from "axios";
import { toast } from "react-toastify";
import { convertIntToMoney, convertMoenyToInt, convertPercentageToFloat, convertPercentageToInt } from "../../helpers/conversions";
import { convertToPercentage } from "../../helpers/masks";
import { ModalDelete } from "../../components/ModalDelete";
import ModalCreateQuote from "../Quote/ModalCreateQuote";
import moment from "moment";
import origins from "../../seeds/origins";

const QuotesTab = ({quotes}) => {
    const [data, setData] = useState({header: [], rows: []});

    useEffect(() => {
        generateData();
    }, []);

    const generateData = () => {
        const header = ['Código', 'Data da solicitação', 'Status'];
        const rows = [];
        
        quotes.map(quote => {
            rows.push({
                data: [
                    quote.code,
                    moment(quote.created_at).format('DD/MM/YYYY HH:mm'),
                    <>
                        <DotStatus color={quote.status_color} size={15} />
                        {quote.status_name}
                    </>
                ],
                to: `/quote/edit/${quote.id}`
            });
        });

        setData({header, rows});
    }

    return (
        <Table header={data.header} rows={data.rows} />
    );
}

const CustomerDetailsTab = ({
    companies,
    customer, 
    name,
    setName,
    email,
    setEmail,
    cpf,
    setCpf,
    phone,
    setPhone,
    selectedCompanies,
    setSelectedCompanies,
    zipCode,
    setZipCode,
    street,
    setStreet,
    number,
    setNumber,
    complement,
    setComplement,
    district,
    setDistrict,
    city,
    setCity,
    state,
    setState
}) => {
    return (
        <div>
            <h2 className="section-title">Dados cadastrais</h2>
            <div className="row">
                <div className="col-2">
                    <Input type={`text`} label={`Código`} value={customer.code} disabled={true} />
                </div>
                <div className="col-5">
                    <Input type={`text`} label={`Nome`} value={name} change={setName} />
                </div>
                <div className="col-5">
                    <Input type={`text`} label={`CPF`} value={cpf} change={setCpf} mask={`convertToCpf`} />
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <Input type={`text`} label={`Telefone`} value={phone} change={setPhone} mask={`convertToPhone`} />
                </div>
                <div className="col-4">
                    <Input type={`text`} label={`E-mail`} value={email} change={setEmail} />
                </div>
                <div className="col-4">
                    <InputSelect isMulti={true} label={`Empresas`} options={createOptions(companies, 'trade_name', 'id')} value={selectedCompanies} change={setSelectedCompanies} />
                </div>
            </div>

            <h2 className="section-title">Endereço</h2>
            <div className="row">
                <div className="col-3">
                    <Input type={`text`} label={`CEP`} value={zipCode} change={setZipCode} mask={`convertToCep`} />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <Input type={`text`} label={`Logradouro`} value={street} change={setStreet} />
                </div>
                <div className="col-3">
                    <Input type={`text`} label={`Número`} value={number} change={setNumber} />
                </div>
                <div className="col-3">
                    <Input type={`text`} label={`Complemento`} value={complement} change={setComplement} />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <InputSelect label={`Estado`} options={states} value={state} change={setState} />
                </div>
                <div className="col-3">
                    <Input type={`text`} label={`Cidade`} value={city} change={setCity} />
                </div>
                <div className="col-3">
                    <Input type={`text`} label={`Bairro`} value={district} change={setDistrict} />
                </div>
            </div>
        </div>
    );
}

export const CustomerEdit = () => {
    const { id } = useParams();
    const navigete = useNavigate();

    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [cpf, setCpf] = useState("");
    const [selectedCompanies, setSelectedCompanies] = useState([]);

    const [zipCode, setZipCode] = useState("");
    const [street, setStreet] = useState("");
    const [number, setNumber] = useState("");
    const [complement, setComplement] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        getCompanies();
        getCustomer();
    }, []);

    useEffect(() => {
        getAddress();
    }, [zipCode]);

    const deleteCustomer = () => {
        setLoading(true);

        api.delete(`/customer/${id}`).then(res => {
            navigete(`/customer/list`);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const getAddress = () => {
        if(zipCode === null) return;
        let convertedZipCode = zipCode.replace('-', '');
        if(convertedZipCode.length === 8){
            axios.get(`https://viacep.com.br/ws/${convertedZipCode}/json/`).then(res => {
                let address = res.data;
                setStreet(address.logradouro);
                setDistrict(address.bairro);
                setCity(address.localidade);
                setState(address.uf);
            })
        }
    }

    const getCompanies = () => {
        api.get(`/company`).then(res => {
            setCompanies(res.data.resources);
        });
    }

    const getCustomer = () => {
        setLoading(true);

        api.get(`/customer/${id}`).then(res => {
            const resource = res.data.resource;
            
            setCustomer(resource);
            setName(resource.name);
            setEmail(resource.email);
            setPhone(resource.phone);
            setCpf(resource.cpf);
            setSelectedCompanies(resource.companies ? resource.companies.map(company => company.id) : []);  

            setZipCode(resource.zip_code ?? '');
            setStreet(resource.street ?? '');
            setNumber(resource.number ?? '');
            setComplement(resource.complement ?? '');
            setDistrict(resource.district ?? '');
            setCity(resource.city ?? '');
            setState(resource.state ?? '');
        }).catch(error => {
            console.log(error)
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updateCustomer = () => {
        setLoading(true);
        api.put(`/customer/${customer.id}`, {
            name,
            email,
            phone,
            cpf,
            company_ids: selectedCompanies,
            zip_code: zipCode,
            street,
            number,
            complement,
            district,
            city,
            state,
        }).then(res => {
            getCustomer();
            toast.success('Pessoa atualizada com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <ModalDelete
                show={confirmDelete}
                action={deleteCustomer}
                close={() => setConfirmDelete(false)}
                loading={loading}
            />
            <PanelTemplate id="customer-edit">
                <Loading show={loading} />
                {!loading &&
                    <BoxTabs
                        titles={[
                            'Detalhes da pessoa',
                            'Orçamentos'
                        ]}
                        contents={[
                            <CustomerDetailsTab
                                companies={companies}
                                customer={customer}
                                name={name}
                                setName={setName}
                                email={email}
                                setEmail={setEmail}
                                cpf={cpf}
                                setCpf={setCpf}
                                phone={phone}
                                setPhone={setPhone}
                                selectedCompanies={selectedCompanies}
                                setSelectedCompanies={setSelectedCompanies}
                                zipCode={zipCode}
                                setZipCode={setZipCode}
                                street={street}
                                setStreet={setStreet}
                                number={number}
                                setNumber={setNumber}
                                complement={complement}
                                setComplement={setComplement}
                                district={district}
                                setDistrict={setDistrict}
                                city={city}
                                setCity={setCity}
                                state={state}
                                setState={setState}
                            />,
                            <QuotesTab
                                quotes={customer.budgets}
                            />
                        ]}
                    />
                }
                <PageFooter spaceBetween={true}>
                    <>
                        <div>
                            <Button type={`primary`} size={`small`} svg={`save-white`} text={`Salvar alterações`} action={updateCustomer} />
                        </div>
                        <Button type={`secondary`} size={`small`} svg={`delete-small`} text={`Excluir`} action={() => setConfirmDelete(true)} />
                    </>
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default CustomerEdit;