import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import DotStatus from "../../components/DotStatus";
import BoxTabs from "../../components/BoxTabs";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import InputRadio from "../../components/InputRadio";
import InputEditor from "../../components/InputEditor";
import InputCheckboxes from "../../components/InputCheckboxes";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import InputColors from "../../components/InputColors";
import PageHeader from "../../components/PageHeader";
import InputTextarea from "../../components/InputTextarea";
import Box from "../../components/Box";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { createOptions } from "../../helpers/selects";
import moment from "moment";
import { convertIntToMoney, convertMoenyToInt } from "../../helpers/conversions";
import ModalProduct from "./ModalProduct";
import { toast } from "react-toastify";
import { ModalDelete } from "../../components/ModalDelete";

const budgetTypes = [
    {label: 'Pessoa física', value: 'PF'},
    {label: 'Pessoa jurídica', value: 'PJ'}
];

const yesOrNoOptions = [
    {label: 'Sim', value: true},
    {label: 'Não', value: false}
];

const shippingTypes = [
    {label: 'CIF', value: 'CIF'},
    {label: 'FOB', value: 'FOB'}
];

const QuoteDetailsTab = ({
    quote,
    billingCompanies,
    selectedBillingCompany,
    setSelectedBillingCompany,
    statuses,
    selectedStatus,
    setSelectedStatus,
    selectedBudgetType,
    setSelectedBudgetType,
    customerCompanies,
    selectedCustomerCompany,
    setSelectedCustomerCompany,
    customers,
    selectedCustomer,
    setSelectedCustomer,
    admins,
    selectedAdmin,
    setSelectedAdmin,
    email,
    phone,
    total,
    comments,
    setComments,
    selectedShowShipping,
    setSelectedShowShipping,
    selectedShippingType,
    setSelectedShippingType,
    shippingFee,
    setShippingFee,
    shippingTime,
    setShippingTime
}) => {
    return (
        <div>
            <div className="row">
                <div className="col-3">
                    <Input
                        label={`Código do orçamento`}
                        value={quote?.code}
                        disabled={true}
                    />
                </div>
                <div className="col-3">
                    <InputSelect
                        label={`Empresa do faturamento`}
                        options={createOptions(billingCompanies, 'trade_name', 'id')}
                        value={selectedBillingCompany}
                        change={setSelectedBillingCompany}
                    />
                </div>
                <div className="col-3">
                    <InputSelect
                        label={`Status do orçamento`}
                        options={createOptions(statuses, 'name', 'id')}
                        value={selectedStatus}
                        change={setSelectedStatus}
                    />
                </div>
                <div className="col-3">
                    <Input
                        label={`Data da solicitação`}
                        value={quote?.created_at ? moment(quote.created_at).format('DD/MM/YYYY HH:mm') : ''}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <InputSelect
                        label={`Tipo do cliente`}
                        options={budgetTypes}
                        value={selectedBudgetType}
                        change={setSelectedBudgetType}
                    />
                </div>
                {selectedBudgetType === "PJ" &&
                    <div className="col-3">
                        <InputSelect
                            label={`Empresa`}
                            options={createOptions(customerCompanies, 'trade_name', 'id')}
                            value={selectedCustomerCompany}
                            change={setSelectedCustomerCompany}
                            />
                    </div>
                }
                <div className="col-3">
                    <InputSelect
                        label={`Pessoa`}
                        options={createOptions(customers, 'name', 'id')}
                        value={selectedCustomer}
                        change={setSelectedCustomer}
                    />
                </div>
                <div className="col-3">
                    <InputSelect
                        label={`Responsável`}
                        options={createOptions(admins, 'name', 'id')}
                        value={selectedAdmin}
                        change={setSelectedAdmin}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <Input
                        label={`E-mail`}
                        value={email}
                        disabled={true}
                    />
                    <Input
                        label={`Telefone`}
                        value={phone}
                        disabled={true}
                    />
                </div>
                <div className="col-3">
                    <Input 
                        label={`Total do orçamento`}
                        value={total}
                        disabled={true}
                    />
                </div>
                <div className="col-6">
                    <InputTextarea label={`Observações`} value={comments} change={setComments} fullHeight={true} />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <InputSelect
                        label={`Mostrar frete`}
                        options={yesOrNoOptions}
                        value={selectedShowShipping}
                        change={setSelectedShowShipping}
                    />
                </div>
                <div className="col-3">
                    <InputSelect
                        label={`Tipo do frete`}
                        options={shippingTypes}
                        value={selectedShippingType}
                        change={setSelectedShippingType}
                    />
                </div>
                <div className="col-3">
                    <Input 
                        label={`Valor do frete`}
                        value={shippingFee}
                        change={setShippingFee}
                        mask={`convertToMoney`}
                    />
                </div>
                <div className="col-3">
                    <Input 
                        label={`Prazo de entrega`}
                        value={shippingTime}
                        change={setShippingTime}
                        right={<span>dias</span>}
                        mask={`convertToInt`}
                    />
                </div>
            </div>
        </div>
    );
}

export const QuoteEdit = () => {
    const { id } = useParams();
    const navigete = useNavigate();

    const [loading, setLoading] = useState(true);

    const [quote, setQuote] = useState(null);

    const [admins, setAdmins] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [billingCompanies, setBillingCompanies] = useState([]);
    const [customerCompanies, setCustomerCompanies] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [allCustomers, setAllCustomers] = useState([]);

    const [selectedBudgetType, setSelectedBudgetType] = useState(null);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedBillingCompany, setSelectedBillingCompany] = useState(null);
    const [selectedCustomerCompany, setSelectedCustomerCompany] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [items, setItems] = useState([]);
    const [itemsData, setItemsData] = useState({header: [], rows: []});

    const [proposalsData, setProposalsData] = useState({header: [], rows: []});

    const [attachmentData, setAttachmentData] = useState({header: [], rows: []});
    
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const [comments, setComments] = useState('');
    const [total, setTotal] = useState('R$ 0,00');

    const [selectedShowShipping, setSelectedShowShipping] = useState(false);
    const [selectedShippingType, setSelectedShippingType] = useState(null);
    const [shippingFee, setShippingFee] = useState('R$ 0,00');
    const [shippingTime, setShippingTime] = useState(0);

    const [itemToEdit, setItemToEdit] = useState(null);
    const [showAddProduct, setShowAddProduct] = useState(false);

    const [productToDelete, setProductToDelete] = useState(null);

    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        getQuote();
        getStatuses();
        getCompanies();
        getAdmins();
        getAllCustomers();
    }, []);

    useEffect(() => {
        if(quote !== null) getItems();
    }, [quote]);

    useEffect(() => {
        generateItemsData();
        getTotal();
    }, [items]);

    useEffect(() => {
        getCustomers();
    }, [selectedCustomerCompany, customerCompanies, selectedBudgetType, allCustomers]);

    useEffect(() => {
        setSelectedCustomer(null);
        setSelectedCustomerCompany(null);
    }, [selectedBudgetType]);

    useEffect(() => {
        getCustomerData();
    }, [selectedCustomer, customers]);

    useEffect(() => {
        if(quote) generateProposalsData();
    }, [quote]);

    const getAllCustomers = () => {
        api.get(`/customer`).then(res => {
            setAllCustomers(res.data.resources);
        });
    }

    const getQuote = () => {
        setLoading(true);

        api.get(`/budget/${id}`).then(res => {
            let resource = res.data.resource;
            console.log(resource);
            setQuote(resource);
            setSelectedBudgetType(resource.type);
            setSelectedStatus(resource.status_id);
            setSelectedAdmin(resource.admin_id);
            setSelectedBillingCompany(resource.billing_company_id);
            setSelectedCustomerCompany(resource.company_id);
            setSelectedCustomer(resource.customer_id);
            setSelectedShowShipping(resource.show_shipping);
            setSelectedShippingType(resource.shipping_type);
            setShippingFee('R$ ' + convertIntToMoney(resource.shipping_fee));
            setShippingTime(resource.shipping_time);
            setComments(resource.comments);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updateQuote = (send = false) => {
        setLoading(true);

        api.put(`/budget/${quote.id}`, {
            send,
            type: selectedBudgetType,
            billing_company_id: selectedBillingCompany,
            company_id: selectedCustomerCompany,
            customer_id: selectedCustomer,
            status_id: selectedStatus,
            admin_id: selectedAdmin,
            products: items,
            show_shipping: selectedShowShipping,
            shipping_fee: convertMoenyToInt(shippingFee),
            shipping_type: selectedShippingType,
            shipping_time: shippingTime,
            comments
        }).then(res => {
            getQuote();
            toast.success('Orçamento atualizada com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const getTotal = () => {
        let toTotal = 0;
        items.map(item => {
            toTotal += item.price * item.quantity;
        });
        setTotal(`R$ ${convertIntToMoney(toTotal)}`);
    }

    const getItems = () => {
        setItems(quote.items);
    }

    const deleteItem = (id) => {
        setItems(prev => {
            let toItems = [];
            prev.map(item => {
                console.log(item.id, id);
                if(item.id !== id){
                    toItems.push(item);
                }
            });
            return toItems;
        });
        setItemToEdit(null);
    }

    const generateItemsData = () => {
        const header = ['Imagem', 'SKU', 'Nome', 'Quant.', 'Preço unit.', 'Total', ''];
        const rows = [];
        
        items.map(item => {
            // const image = <img src={logo.image_url.small} />;
            let image =  null;
            if(item.subproduct.image !== null){
                image = <img src={item.subproduct.image.image_url.small} />;
            }
            if(item.subproduct.product.images.length > 0 && image === null){
                image = <img src={item.subproduct.product.images[0].image_url.small} />;
            }

            if(image === null){
                image = <img src={ require(`../../assets/images/svgs/default.svg`).default } />;
            }

            rows.push({
                data: [
                    image, 
                    item.subproduct.sku,
                    item.subproduct.product.name,
                    item.quantity, 
                    `R$${convertIntToMoney(item.price)}`, 
                    `R$${convertIntToMoney(item.price * item.quantity)}`
                    // <Button
                    //     type={`transparent`}
                    //     size={`samll`}
                    //     svg={`delete`}
                    //     action={() => deleteItem(item.id)}
                    // />
                ],
                buttons: [
                    <Button type={`delete`} svg={`delete`} action={() => setProductToDelete(item.id)} />
                ],
                action: () => setItemToEdit(item)
            });
        });

        setItemsData({header, rows});
    }

    const generateProposalsData = () => {
        const header = ['Data do envio', 'Responsável', 'Nome do contato', 'E-mail do contato', 'Total da proposta'];
        const rows = [];
        
        quote.proposals.map(proposal => {
            rows.push({
                data: [
                    moment(proposal.created_at).format('DD/MM/YYYY HH:mm'),
                    proposal.admin_name, 
                    proposal.customer_name,
                    proposal.customer_email,
                    `R$${convertIntToMoney(proposal.total)}`,
                ],
                action: () => navigete(`/proposal/${proposal.id}`)
            });
        });

        setProposalsData({header, rows});
    }

    const getStatuses = () => {
        api.get(`/status`).then(res => {
            setStatuses(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getAdmins = () => {
        api.get(`/list`).then(res => {
            setAdmins(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getCompanies = () => {
        api.get(`/company`).then(res => {
            let toCustomerCompanies = [];
            let toBillingCompanies = [];
            res.data.resources.map(resource => {
                if(resource.type.includes('customer')){
                    toCustomerCompanies.push(resource);
                }
                if(resource.type.includes('billing')){
                    toBillingCompanies.push(resource);
                }
            });
            setBillingCompanies(toBillingCompanies);
            setCustomerCompanies(toCustomerCompanies);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getCustomers = () => {
        if(selectedBudgetType === "PJ"){
            let company = customerCompanies.filter(cc => cc.id === selectedCustomerCompany);
            if(company.length > 0){
                setCustomers(company[0].customers);
            }
        }
        if(selectedBudgetType === "PF"){
            setCustomers(allCustomers);
        }
    }

    const getCustomerData = () => {
        let customer = customers.filter(c => c.id === selectedCustomer);
        if(customer.length > 0){
            console.log(customer[0].phone);
            setEmail(customer[0].email);
            setPhone(customer[0].phone);
        } else {
            setEmail('');
            setPhone('');
        }
    }

    const ProposalsTab = () => {
        return (
            <Table header={proposalsData.header} rows={proposalsData.rows}/>
        );
    }

    const AttachmentTab = () => {
        return (
            <Table header={attachmentData.header} rows={attachmentData.rows}/>
        );
    }

    const deleteQuote = () => {
        setLoading(true);

        api.delete(`/budget/${id}`).then(res => {
            navigete(`/quote/list`);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteProduct = () => {
        setLoading(true);

        api.delete(`/budget/item/${productToDelete}`).then(res => {
            setProductToDelete(null);
            getQuote();
        }).catch(error => {
            setLoading(false);
            renderError(error);
        });
    }

    return (
        <>
            {showAddProduct &&
                <ModalProduct
                    quote={quote}
                    getQuote={getQuote}
                    billingCompanies={billingCompanies}
                    selectedBillingCompany={selectedBillingCompany}
                    setSelectedBillingCompany={setSelectedBillingCompany}
                    close={() => setShowAddProduct(false)}
                />
            }
            {itemToEdit !== null &&
                <ModalProduct
                    quote={quote}
                    getQuote={getQuote}
                    itemToEdit={itemToEdit}
                    setItemToEdit={setItemToEdit}
                    billingCompanies={billingCompanies}
                    selectedBillingCompany={selectedBillingCompany}
                    setSelectedBillingCompany={setSelectedBillingCompany}
                    close={() => setItemToEdit(null)}
                />
            }
            <ModalDelete
                show={productToDelete !== null}
                action={deleteProduct}
                loading={loading}
                close={() => setProductToDelete(null)}
            />
            <ModalDelete
                show={confirmDelete}
                action={deleteQuote}
                loading={loading}
                close={() => setConfirmDelete(false)}
            />
            <PanelTemplate id="quote-edit">
                <BoxTabs
                    titles={[
                        'Detalhes do orçamento',
                        'Propostas enviadas',
                        // 'Anexos',
                    ]}
                    contents={[
                        <QuoteDetailsTab
                            quote={quote}
                            billingCompanies={billingCompanies}
                            selectedBillingCompany={selectedBillingCompany}
                            setSelectedBillingCompany={setSelectedBillingCompany}
                            statuses={statuses}
                            selectedStatus={selectedStatus}
                            setSelectedStatus={setSelectedStatus}
                            selectedBudgetType={selectedBudgetType}
                            setSelectedBudgetType={setSelectedBudgetType}
                            customerCompanies={customerCompanies}
                            selectedCustomerCompany={selectedCustomerCompany}
                            setSelectedCustomerCompany={setSelectedCustomerCompany}
                            customers={customers}
                            selectedCustomer={selectedCustomer}
                            setSelectedCustomer={setSelectedCustomer}
                            admins={admins}
                            selectedAdmin={selectedAdmin}
                            setSelectedAdmin={setSelectedAdmin}
                            email={email}
                            phone={phone}
                            total={total}
                            comments={comments}
                            setComments={setComments}
                            selectedShowShipping={selectedShowShipping}
                            setSelectedShowShipping={setSelectedShowShipping}
                            selectedShippingType={selectedShippingType}
                            setSelectedShippingType={setSelectedShippingType}
                            shippingFee={shippingFee}
                            setShippingFee={setShippingFee}
                            shippingTime={shippingTime}
                            setShippingTime={setShippingTime}
                        />,
                        <ProposalsTab />,
                        // <AttachmentTab />,
                    ]}
                />
                {billingCompanies.length > 0 &&
                    <Box
                        className={`no-padding`}
                        title={`Produtos do orçamento`}
                        tools={
                            <Button 
                                type={`secondary`}
                                size={`small`}
                                text={`Adicionar produto`}
                                loading={loading}
                                svg={`plus-blue`}
                                action={() => setShowAddProduct(true)}
                            />
                        }
                    >
                        <Table header={itemsData.header} rows={itemsData.rows}/>
                    </Box>
                }
                <PageFooter spaceBetween={true}>
                    <div>
                        <Button type={`primary`} loading={loading} size={`small`} svg={`save-white`} text={`Salvar alterações`} action={updateQuote} />
                        <Button type={`secondary`} loading={loading} size={`small`} svg={`send`} text={`Salvar e enviar`} action={() => updateQuote(true)} />
                    </div>
                    <div>
                        <Button type={`secondary`} size={`small`} loading={loading} text={`Salvar e emitir pedido`} action={() => navigete(`/order/create/${quote?.id}`)} />
                        <Button type={`secondary`} size={`small`} loading={loading} text={`Excluir`} svg={`delete-small`} action={() => setConfirmDelete(true)} />
                    </div>
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default QuoteEdit;