import React, { useEffect, useState } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Box from "../../Box";
import DotStatus from "../../DotStatus";
import { convertIntToMoney } from "../../../helpers/conversions";

ChartJS.register(ArcElement, Tooltip, Legend);


export const options = {
  plugins: {
    legend: {
      display: false
    }
  }
}

export const BoxDoughnutChart = ({totalByStatus}) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
      getChartData();
    }, [totalByStatus]);

    const getChartData = () => {
      const toChartData = {
        legend: {
            display: false,
        },
        datasets: [
          {
            label: '',
            borderWidth: 0,
          },
        ],
      };

      let labels = [];
      let data = [];
      let backgroundColor = [];

      totalByStatus.map((total, index) => {
        labels[index] = total.status.name;
        data[index] = total.total_budgets;
        backgroundColor[index] = total.status.color;
      });

      toChartData[labels] = labels;
      toChartData.datasets[0].data = data;
      toChartData.datasets[0].backgroundColor = backgroundColor;
      
      setChartData(toChartData);
    }

    return (
        <Box>
            <div className="chart">
              {chartData !== null &&
                <Doughnut data={chartData} options={options} />
              }
            </div>
            <table className="chart-table">
              {totalByStatus.map((total, index) => (
                <tr key={index}>
                  <td><DotStatus color={total.status.color} size={15} /> {total.status.name}</td>
                  <td>{total.total_budgets}</td>
                  <td>R${convertIntToMoney(total.amount_budgets)}</td>
                </tr>
              ))}
            </table>
        </Box>
    );
}

export default BoxDoughnutChart;