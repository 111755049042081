import React, { useEffect } from "react";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import InputCheckboxUnique from "../../../../components/InputCheckboxUnique";
import InputCheckboxes from "../../../../components/InputCheckboxes";
import { createOptions } from "../../../../helpers/selects";

const MultiplePercentageOptions = ({options, setOptions, users}) => {
    const base = {
        admin_ids: [],
        description: '',
        value: '0,0000 %'
    };

    useEffect(() => {
        if(options.length === 0){
            setOptions([base]);
        }
    }, []);
    
    const handleAdminChange = (index, user) => {
        let toOptions = [...options];
        if(toOptions[index].admin_ids.includes(user.id)){
            toOptions[index].admin_ids = toOptions[index].admin_ids.filter(id => user.id !== id);
        } else {
            toOptions[index].admin_ids.push(user.id);
        }

        setOptions(toOptions);
    }

    const addOptions = () => {
        let toOptions = [...options];
        toOptions.push(base);
        setOptions(toOptions);
    }

    const deleteOption = index => {
        let toOptions = [];
        options.map((option, i) => {
            if(index !== i) toOptions.push(option);
        });
        setOptions(toOptions);
    }

    return (
        <>
            {options.map((option, index) => (
                <div key={index} className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input 
                                type={`text`} 
                                label={`Descrição`} 
                                value={option.description}
                                change={value => setOptions(prev => {
                                    prev[index].description = value;
                                    return [...prev];
                                })}
                            />
                        </div>
                        <div className="col-5">
                            <Input 
                                type={`text`}
                                label={`Porcentagem`} 
                                value={option.value} 
                                change={value => setOptions(prev => {
                                    prev[index].value = value;
                                    return [...prev];
                                })}
                                mask={`convertToPercentage`} 
                            />
                        </div>
                        <div className="col-1">
                            <Button type={`delete`} svg={`delete-small`} action={() => deleteOption(index)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {users.map(user => (
                                <InputCheckboxUnique key={user.id} label={`${user.name} - ${user.email}`} change={() => handleAdminChange(index, user)} checked={option.admin_ids.includes(user.id)} />
                            ))}
                        </div>
                    </div>
                </div>
            ))}

            <Button 
                type={`link`}
                size={`small`}
                text={`Cadastrar mais opções`}
                full={true}
                action={addOptions}
            />
        </>
    );
}

export default MultiplePercentageOptions;