import React, { useEffect, useState } from "react";
import Select from 'react-select';

import PanelTemplate from "../../templates/Panel";
import BoxMoney from "../../components/dashboard/BoxMoney";
import BoxDoughnutChart from "../../components/dashboard/BoxDoughnutChart";
import BoxCount from "../../components/dashboard/BoxCount";
import { Filter } from "../../components/dashboard/Filter";
import Table from "../../components/Table";
import Box from "../../components/Box";
import BoxVerticalBarChart from "../../components/dashboard/BoxVerticalBarChart";
import Modal from "../../components/Modal";
import api from "../../services/api";
import { convertIntToMoney } from "../../helpers/conversions";

const options = [
    { value: 'approved', label: 'Aprovado' },
    { value: 'wating', label: 'Aguardando' },
    { value: 'pending', label: 'Pendente' }
];

const data = {
    header: ['Nome do vendedor', 'Num. de orçamentos', 'Total'],
    rows: []
}

export const Dashboard = () => {
    const [startDate, setStartDate] = useState('2022-05-01');
    const [finalDate, setFinalDate] = useState('2022-05-30');

    const [amountBudgets, setAmountBudgets] = useState(0);
    const [averageTicket, setAverageTicket] = useState(0);
    const [averageItems, setAverageItems] = useState(0);
    const [averageProposals, setAverageProposals] = useState(0);

    const [totalByStatus, setTotalByStatus] = useState([]);
    const [totalByAdmin, setTotalByAdmin] = useState([]);
    const [totalByMonth, setTotalByMonth] = useState([]);

    const [adminData, setAdminData] = useState({header: [], rows: []});

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getAdminData();
    }, [totalByAdmin]);

    const getData = () => {
        api.get(`/dashboard`, {
            params: {
                start_date: startDate,
                final_date: finalDate
            }
        }).then(res => {
            const { data } = res;
            setAmountBudgets(data.amount_budgets);
            setAverageTicket(data.average_ticket);
            setAverageItems(data.average_items);
            setAverageProposals(data.average_proposals);

            setTotalByStatus(data.budgets_by_status);
            setTotalByAdmin(data.budgets_by_admin);
            setTotalByMonth(data.budgets_by_month);
        });
    }

    const getAdminData = () => {
        const header = ['Nome do vendedor', 'Num. de orçamentos', 'Total'];
        const rows = [];
        
        totalByAdmin.map(admin => {
            rows.push({
                data: [
                    admin.admin.name,
                    admin.total_budgets,
                    `R$ ${convertIntToMoney(admin.amount_budgets)}`
                ]
            })
        });

        setAdminData({header, rows});
    }

    return (
        <>
            <PanelTemplate id="dashboard">
                <Filter />
                <div className="row">
                    <div className="col">
                        <BoxMoney
                            text={`Valor total de todos os orçamentos realizados no período`}
                            value={amountBudgets}
                        />
                        <BoxMoney
                            text={`Ticket médido dos orçamentos realizados no período`}
                            value={averageTicket}
                        />
                    </div>
                    <div className="col">
                        <BoxDoughnutChart totalByStatus={totalByStatus}  />
                    </div>
                    <div className="col">
                        <BoxCount
                            text={`Número médio de itens nos orçamentos`}
                            value={averageItems}
                        />
                        <BoxCount
                            text={`Número médio de propostas enviadas por orçamento`}
                            value={averageProposals}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Box 
                            className={`no-padding`}
                            title={`Desempenho dos vendedores`}
                            // tools={<Select options={options} />}
                        >
                            <Table header={adminData.header} rows={adminData.rows} />
                        </Box>
                    </div>
                    <div className="col">
                        <BoxVerticalBarChart totalByMonth={totalByMonth}  />
                    </div>
                </div>
            </PanelTemplate>
        </>
    );
}

export default Dashboard;