import React, { useEffect, useState } from "react";

import InputSelect from "../../../../components/InputSelect";
import Input from "../../../../components/Input";
import InputRadio from "../../../../components/InputRadio";
import { createOptions } from '../../../../helpers/selects';
import { convertIntToMoney, convertMoenyToInt } from "../../../../helpers/conversions";
import Button from "../../../../components/Button";
import { calculateCustomizationTotal } from "../customizationCalculate";

const CustomizationSection = ({
    index,
    calculationTypeOptions,
    customizations,
    productCustomization,
    productCustomizations,
    setProductCustomizations,
    productQuantity
}) => {
    const [suppliers, setSuppliers] = useState([]);
    const [calculationPer, setCalculationPer] = useState('unit');
    const [customizationUnitCostError, setCustomizationUnitCostError] = useState('');

    useEffect(() => {
        getSuppliers();
        getCalculationPer();
    }, [,productCustomization.selectedCustomization]);

    useEffect(() => {
        handleQuantityChange(productQuantity);
    }, [productQuantity]);

    useEffect(() => {
        getCustomizationUnitCost();
    }, 
    [
        productCustomization.calculationType,
        productCustomization.selectedSupplierCustomization,
        productCustomization.selectedCustomization,
        productCustomization.quantity,
        productCustomization.quantityColors,
        suppliers
    ]);

    useEffect(() => {
        calculateCustomizationTotalCost();
    }, [
        productCustomization.quantity,
        productCustomization.quantityColors,
        productCustomization.customizationUnitCost
    ]);

    const zeroUnitCost = () => {
        handleCustomizationUnitCostChange( `R$ 0,00` );
    }

    const getSuppliers = () => {
        let toSuppliers = [];
        let customization = customizations.filter(c => c.id === productCustomization.selectedCustomization);
        if(customization.length > 0){
            customization = customization[0];
            customization.companies.map(company => {
                toSuppliers.push({
                    id: company.id,
                    name: company.trade_name,
                    type: "PJ"
                });
            });
            customization.customers.map(customer => {
                toSuppliers.push({
                    id: customer.id,
                    name: customer.name,
                    type: "PF"
                });
            });
        }
        setSuppliers(toSuppliers);
    }

    const calculateCustomizationTotalCost = () => {
        let total = calculateCustomizationTotal(
            productCustomization.quantity,
            convertMoenyToInt( productCustomization.customizationUnitCost),
            getCalculationPer()
        );
        let customization = customizations.filter(c => c.id === productCustomization.selectedCustomization);
        if(customization.length > 0){
            customization = customization[0];
            if(customization.multiply_colors){
                total = total * productCustomization.quantityColors;
            }
        }

        handleCustomizationTotalCostChange( `R$ ${convertIntToMoney(total)}`);
    }


    const handleCalculationTypeChange = value => {
        handleParamChange('calculationType', value);
    }

    const handleSelectedCustomizationChange = value => {
        if(value !== productCustomization.selectedCustomization){
            handleSelectedSupplierCustomizationChange(null);
            zeroUnitCost();
        }
        handleParamChange('selectedCustomization', value);
    }

    const handleSelectedSupplierCustomizationChange = value => {
        let supplier = getSupplier(value);
        handleParamChange('selectedSupplierTypeCustomization', supplier ? supplier.type : null);
        zeroUnitCost();
        handleParamChange('selectedSupplierCustomization', value);
    }

    const handleQuantityColorsChange = value => {
        handleParamChange('quantityColors', value);
    }

    const handleQuantityChange = value => {
        handleParamChange('quantity', value);
    }

    const handleCustomizationUnitCostChange = value => {
        handleParamChange('customizationUnitCost', value);
    }

    const handleCustomizationTotalCostChange = value => {
        handleParamChange('customizationTotalCost', value);
    }

    const getCalculationPer = () => {
        let toCalculationPer = 'unit';
        let customization = customizations.filter(c => c.id === productCustomization.selectedCustomization);
        
        if(customization.length > 0){
            customization = customization[0];
            toCalculationPer = customization.calculation_per;
        }

        setCalculationPer(toCalculationPer);
        return toCalculationPer;
    }

    const handleParamChange = (param, value) => {
        console.log(param, value)
        setProductCustomizations(prev => {
            let toProductCustomizations = [];

            prev.map((c, i) => {
                if(i === index){
                    let updated = {...c};
                    updated[param] = value;
                    toProductCustomizations.push(updated);
                } else {
                    toProductCustomizations.push(c);
                }
            });

            return toProductCustomizations;
        });
    }

    const getSupplier = id => {
        let supplier = suppliers.filter(s => s.id === id);
        return supplier.length > 0 ? supplier[0] : null;
    }

    const getCustomizationUnitCost = () => {
        setCustomizationUnitCostError('');

        if(productCustomization.calculationType === 'manual') return;

        let cost = 0;
        let error = 'Preço do fornecedor não encontrado.';
        let foundPrice = false;
        let prices = [];
        const quantity = productCustomization.quantity;
        let thousands = quantity / 1000;
        thousands = Math.ceil(thousands);

        let customization = customizations.filter(c => c.id === productCustomization.selectedCustomization);
        if(customization.length > 0){
            let supplier = getSupplier(productCustomization.selectedSupplierCustomization);

            customization = customization[0];
            console.log('calc', productCustomization, suppliers)

            if(supplier?.type === "PJ"){
                customization.companies.map(company => {
                    if(company.id === productCustomization.selectedSupplierCustomization){
                        prices.push(company.pivot);
                    }
                });
            }
            if(supplier?.type === "PF"){
                customization.customers.map(customer => {
                    if(customer.id === productCustomization.selectedSupplierCustomization){
                        prices.push(customer.pivot);
                    }
                });
            }
        }

        prices = sortPrices(prices);

        prices.map(price => {
            if(customization.calculation_per === 'unit'){
                if(price.init_qtd <= quantity && price.final_qtd >= quantity){
                    cost = price.cost;
                    error = '';
                    foundPrice = true;
                }
            } else {
                if(price.thousand === thousands){
                    cost = price.cost;
                    error = '';
                    foundPrice = true;
                }
            }
        });

        if(!foundPrice && prices.length > 0){
            let price = prices[prices.length -1];
            cost = price.cost;
            error = '';
        }

        handleCustomizationUnitCostChange( `R$ ${convertIntToMoney(cost)}` );
        setCustomizationUnitCostError(error);
    }
    
    const sortPrices = prices => {
        if(calculationPer === 'unit'){
            return prices.sort((a, b) => a.final_qtd - b.final_qtd);
        } else {
            return prices.sort((a, b) => a.thousand - b.thousand);
        }
    }

    const deleteCustomization = () => {
        let toProductCustomizations = [];
        productCustomizations.map((productCustomization, i) => {
            if(i !== index){
                toProductCustomizations.push(productCustomization);
            }
        });

        setProductCustomizations([...toProductCustomizations]);
    }

    return (
        <>
            <div className="modal-section-title">
                <div className="left">
                    <h2>Gravação</h2>
                    <Button type={`transparent`} size={`small`} svg={`delete-small`} action={deleteCustomization} />
                </div>
                <div className="right">
                    <InputRadio
                        className={`no-margin`}
                        options={calculationTypeOptions}
                        value={productCustomization.calculationType}
                        change={handleCalculationTypeChange}
                    />
                </div>
            </div>
        
            <div className="section">
                <div className="row">
                    <div className="col-6">
                        <InputSelect 
                            options={createOptions(customizations, 'name', 'id', true)}
                            value={productCustomization.selectedCustomization}
                            change={handleSelectedCustomizationChange}
                            label={`Gravação`}
                        />
                    </div>
                    <div className="col-6">
                        <InputSelect 
                            label={`Fornecedor`}
                            options={createOptions(suppliers, 'name', 'id', true)}
                            value={productCustomization.selectedSupplierCustomization}
                            change={handleSelectedSupplierCustomizationChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <Input
                            label={`Num. de cores`}
                            value={productCustomization.quantityColors}
                            change={handleQuantityColorsChange}
                            mask={`convertToInt`}
                        />
                    </div>
                    {/* <div className="col-3">
                        <Input
                            label={`Quantidade`}
                            value={productCustomization.quantity}
                            change={handleQuantityChange}
                            mask={`convertToInt`}
                        />
                    </div> */}
                    <div className="col-4">
                        <Input
                            label={calculationPer === 'unit' ? 'Custo unit.' : 'Custo milheiro'} 
                            value={productCustomization.customizationUnitCost}
                            change={handleCustomizationUnitCostChange}
                            mask={`convertToMoney`}
                            disabled={productCustomization.calculationType === 'automatic'}
                            error={customizationUnitCostError}
                        />
                    </div>
                    <div className="col-4">
                        <Input
                            label={`Total`}
                            value={productCustomization.customizationTotalCost}
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CustomizationSection;