export const getIndexFromObjectArray = (arr, param, value) => {
    let index = null;
    if(arr){
        arr.map((object, i) => {
            if(object[param] === value){
                index = i;
            }
        });
    }
    return index;
}