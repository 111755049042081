import React, { useState, useEffect } from "react";

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import { renderError } from "../../helpers/errors";
import api from "../../services/api";
import moment from "moment";
import { convertIntToMoney } from "../../helpers/conversions";

export const OrderList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');

    const [orders, setOrders] = useState([]);
    const [data, setData] = useState({header: [], rows: []});


    useEffect(() => {
        getOrders();
    }, [,filters]);

    useEffect(() => {
        generateData();
    }, [orders]);

    const getOrders = () => {
        setLoading(true);

        api.get(`/order${filters}`).then(res => {
            setOrders(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Código', 'Nome fantasia', 'Contato', 'Total', 'Data da emissão'];
        const rows = [];
        
        orders.map(order => {
            rows.push({
                data: [
                    order.code,
                    order?.customer_company_trade_name,
                    order?.customer_name,
                    `R$ ${convertIntToMoney(order?.total)}`,
                    moment(order.created_at).format('DD/MM/YYYY HH:mm'),
                ],
                to: `/order/edit/${order?.id}`
            });
        });

        setData({header, rows});
    }

    return (
        <>
            <PanelTemplate id="order-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome fantasia', key: 'customer_company_trade_name', type: 'text' },
                                {name: 'Razão social', key: 'customer_company_company_name', type: 'text' },
                                {name: 'E-mail do contato', key: 'customer_email', type: 'text' }
                            ]}
                            filter={setFilters}
                        />
                    }

                    // right={
                    //     <>
                    //         <Button type={`secondary`} size={`small`} text={`Filtrar`} svg="filter" />
                    //         {/* <Button type={`primary`} size={`small`} text={`Cadastrar pedido`} svg="plus-white" action={() => navigate(`/quote/create`)}  /> */}
                    //     </>
                    // }
                />
                
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows}/>
                </Box>
            </PanelTemplate>
        </>
    );
}

export default OrderList;