import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PanelTemplate from "../../templates/Panel";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import Box from "../../components/Box";
import Table from "../../components/Table";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import Input from "../../components/Input";
import { convertIntToMoney, convertMoenyToInt } from "../../helpers/conversions";
import InputSelect from "../../components/InputSelect";
import states from "../../seeds/states";
import axios from "axios";
import InputTextarea from "../../components/InputTextarea";
import { toast } from "react-toastify";

const recipientTypes = [
    {label: 'Pessoa física', value: 'PF'},
    {label: 'Pessoa jurídica', value: 'PJ'}
];

export const OrderCreate = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [quote, setQuote] = useState(null);

    const [shippingCompany, setShippingCompany] = useState('');
    const [shippingService, setShippingService] = useState('');
    const [shippingPrice, setShippingPrice] = useState('');
    const [paymentTerm, setPaymentTerm] = useState('');

    const [comments, setComments] = useState('');

    const [recipientCpf, setRecipientCpf] = useState('');
    const [recipientName, setRecipientName] = useState('');

    const [recipientCompanyName, setRecipientCompanyName] = useState('');
    const [recipientTradeName, setRecipientTradeName] = useState('');
    const [recipientZipCode, setRecipientZipCode] = useState('');
    const [recipientStreet, setRecipientStreet] = useState('');
    const [recipientNumber, setRecipientNumber] = useState('');
    const [recipientComplement, setRecipientComplement] = useState('');
    const [recipientCity, setRecipientCity] = useState('');
    const [recipientState, setRecipientState] = useState('');
    const [recipientDistrict, setRecipientDistrict] = useState('');
    const [recipientCnpj, setRecipientCnpj] = useState('');
    const [recipientIe, setRecipientIe] = useState('');
    const [recipientRg, setRecipientRg] = useState('');
    const [recipientPhone, setRecipientPhone] = useState('');
    const [recipientEmail, setRecipientEmail] = useState('');

    const [selectedRecipientType, setSelectedRecipientType] = useState(null);

    const [total, setTotal] = useState('R$ 0,00');

    useEffect(() => {
        getQuote();
    }, []);

    useEffect(() => {
        getTotal();
    }, [shippingPrice, quote]);

    useEffect(() => {
        getAddress();
    }, [recipientZipCode]);

    const getQuote = () => {
        setLoading(true);

        api.get(`/budget/${id}`).then(res => {
            let resource = res.data.resource;
            setQuote(resource);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getTotal = () => {
        const shippingPriceConverted = convertMoenyToInt(shippingPrice);
        const quoteTotal = quote?.total ? quote.total : 0;
        const toTotal = shippingPriceConverted + quoteTotal;
        setTotal(`R$ ${convertIntToMoney(toTotal)}`);
    }

    const getAddress = () => {
        if(recipientZipCode === null) return;
        let convertedZipCode = recipientZipCode.replace('-', '');
        if(convertedZipCode.length === 8){
            axios.get(`https://viacep.com.br/ws/${convertedZipCode}/json/`).then(res => {
                let address = res.data;
                setRecipientStreet(address.logradouro);
                setRecipientDistrict(address.bairro);
                setRecipientCity(address.localidade);
                setRecipientState(address.uf);
            })
        }
    }

    const store = () => {
        setLoading(true);
        
        api.post(`/order`, {
            budget_id: quote.id,
            recipient_type: selectedRecipientType,
            recipient_company_name: recipientCompanyName,
            recipient_trade_name: recipientTradeName,
            recipient_zip_code: recipientZipCode,
            recipient_street: recipientStreet,
            recipient_number: recipientNumber,
            recipient_complement: recipientComplement,
            recipient_city: recipientCity,
            recipient_state: recipientState,
            recipient_district: recipientDistrict,
            recipient_cnpj: recipientCnpj,
            recipient_ie: recipientIe,
            recipient_rg: recipientIe,
            recipient_phone: recipientPhone,
            recipient_email: recipientEmail,
            payment_term: paymentTerm,
            shipping_company: shippingCompany,
            shipping_service: shippingService,
            shipping_price: convertMoenyToInt(shippingPrice),
            comments,
        }).then(res => {
            toast.success(`Pedido emitido com sucesso`);
            navigate(`/order/list`);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const setRecipientData = () => {
        setSelectedRecipientType(quote.type);
        console.log(quote);
        setRecipientCpf(quote.customer.cpf);
        setRecipientName(quote.customer.name);

        if(quote.type === "PJ"){
            setRecipientCompanyName(quote.company.company_name);
            setRecipientTradeName(quote.company.trade_name);
        }

        setRecipientCnpj(quote.type === "PF" ? quote.customer.cpf : quote.company.cnpj);

        setRecipientPhone(quote.customer.phone);
        setRecipientEmail(quote.customer.email);

        setRecipientZipCode(quote.type === "PF" ? quote.customer.zip_code : quote.company.zip_code);
        setRecipientStreet(quote.type === "PF" ? quote.customer.street : quote.company.street);
        setRecipientNumber(quote.type === "PF" ? quote.customer.number : quote.company.number);
        setRecipientComplement(quote.type === "PF" ? quote.customer.complement : quote.company.complement);
        setRecipientCity(quote.type === "PF" ? quote.customer.city : quote.company.city);
        setRecipientState(quote.type === "PF" ? quote.customer.state : quote.company.state);
        setRecipientDistrict(quote.type === "PF" ? quote.customer.district : quote.company.district);
        
    }

    return (
        <>
            <PanelTemplate id="order-create">
                <Box
                    title={`Dados do pedido`}
                >
                    <div className="row">
                        <div className="col-3">
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <Input label={`Código do orçamento`} value={quote?.code} disabled={true} />
                            <Input
                                label={`Condição de pagamento`} 
                                value={paymentTerm}
                                change={setPaymentTerm}
                            />
                        </div>
                        <div className="col-9">
                            <InputTextarea label={`Observações`} fullHeight={true} value={comments} change={setComments} />
                        </div>
                    </div>
                </Box>
                <Box
                    title={`Endereço de entrega`}
                >
                    <div className="row">
                        <div className="col">
                            <Button 
                                type={`secondary`} 
                                size={`small`} 
                                text={`Preencher com os dados do cliente`}
                                action={setRecipientData}
                            />
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <InputSelect label={`Tipo de recebdor`} options={recipientTypes} value={selectedRecipientType} change={setSelectedRecipientType} />
                        </div>
                    </div>
                    {selectedRecipientType === "PJ" &&
                        <div className="row">
                            <div className="col-3">
                                <Input type={`text`} label={`CNPJ`} value={recipientCnpj} change={setRecipientCnpj} mask={`convertToCnpj`} />
                            </div>
                            <div className="col-4">
                                <Input type={`text`} label={`Nome fantasia`} value={recipientTradeName} change={setRecipientTradeName} />
                            </div>
                            <div className="col-4">
                                <Input type={`text`} label={`Razão social`} value={recipientCompanyName} change={setRecipientCompanyName} />
                            </div>
                        </div>
                    }
                    {selectedRecipientType === "PF" &&
                        <div className="row">
                            <div className="col-3">
                                <Input type={`text`} label={`CPF`} value={recipientCpf} change={setRecipientCpf} mask={`convertToCpf`} />
                            </div>
                            <div className="col-4">
                                <Input type={`text`} label={`Nome`} value={recipientName} change={setRecipientName} />
                            </div>
                        </div>
                    }
                    {selectedRecipientType !== null &&
                    <>
                    <div className="row">
                        <div className="col-3">
                            <Input type={`text`} label={`Telefone`} value={recipientPhone} change={setRecipientPhone} mask={`convertToPhone`} />
                        </div>
                        <div className="col-4">
                            <Input type={`text`} label={`E-mail`} value={recipientEmail} change={setRecipientEmail} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <Input type={`text`} label={`CEP`} value={recipientZipCode} change={setRecipientZipCode} mask={`convertToCep`} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Logradouro`} value={recipientStreet} change={setRecipientStreet} />
                        </div>
                        <div className="col-3">
                            <Input type={`text`} label={`Número`} value={recipientNumber} change={setRecipientNumber} />
                        </div>
                        <div className="col-3">
                            <Input type={`text`} label={`Complemento`} value={recipientComplement} change={setRecipientComplement} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <InputSelect label={`Estado`} options={states} value={recipientState} change={setRecipientState} />
                        </div>
                        <div className="col-3">
                            <Input type={`text`} label={`Cidade`} value={recipientCity} change={setRecipientCity} />
                        </div>
                        <div className="col-3">
                            <Input type={`text`} label={`Bairro`} value={recipientDistrict} change={setRecipientDistrict} />
                        </div>
                    </div>
                    </>
                    }
                </Box>
                <PageFooter>
                    <Button type={`primary`} size={`small`} svg={`save-white`} text={`Emitir pedido`} action={store} loading={loading} />
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default OrderCreate;