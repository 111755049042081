import React, { useState, useEffect } from "react";
import Select from 'react-select';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import ModalCreateQuote from "./ModalCreateQuote";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { convertIntToMoney } from "../../helpers/conversions";
import { ModalDelete } from "../../components/ModalDelete";

export const QuoteList = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState('');
    const [quotes, setQuotes] = useState([]);
    const [data, setData] = useState({header: [], rows: []});
    const [showCreateQuote, setShowCreateQuote] = useState(false);
    const [tab, setTab] = useState("Aguardando orçamento");
    const [quoteToDelete, setQuoteToDelete] = useState(null);

    useEffect(() => {
        getQuotes();
    }, [,filters]);

    useEffect(() => {
        generateData();
    }, [quotes, tab]);

    const getQuotes = () => {
        setLoading(true);
        api.get(`/budget${filters}`).then(res => {
            setQuotes(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Código', 'Data da solicitação', 'Nome fantasia', 'Pessoa', 'Status', 'Total', ''];
        const rows = [];
        
        quotes.map(quote => {
            if(tab === 'Orçamento aprovado' && quote.status_name !== 'Orçamento aprovado') return;
            if(tab === 'Pendente de aprovação' && quote.status_name !== 'Pendente de aprovação') return;
            if(tab === 'Aguardando orçamento' && quote.status_name !== 'Aguardando orçamento') return;
            if(tab === 'Não aprovado' && quote.status_name !== 'Não aprovado') return;
            rows.push({
                data: [
                    quote.code,
                    moment(quote.created_at).format('DD/MM/YYYY HH:mm'),
                    quote?.company?.trade_name,
                    quote?.customer?.name,
                    <>
                        <DotStatus color={quote.status_color} size={15} />
                        {quote.status_name}
                    </>,
                    `R$ ${convertIntToMoney(quote.total)}`
                ],
                buttons: [
                    <Button type={`delete`} svg={`delete`} action={() => setQuoteToDelete(quote.id)} />
                ],
                to: `/quote/edit/${quote.id}`
            });
        });

        setData({header, rows});
    }

    const finishCreation = () => {
        getQuotes();
    }

    const deleteQuote = () => {
        setLoading(true);

        api.delete(`/budget/${quoteToDelete}`).then(res => {
            getQuotes();
            setQuoteToDelete(null);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            {showCreateQuote &&
                <ModalCreateQuote
                finishCreation={finishCreation}
                    close={() => setShowCreateQuote(false)}
                />
            }
            <ModalDelete
                show={quoteToDelete !== null}
                action={deleteQuote}
                loading={loading}
                close={() => setQuoteToDelete(null)}
            />
            <PanelTemplate id="quote-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome fantasia', key: 'trade_name', type: 'text' },
                                {name: 'Razão social', key: 'company_name', type: 'text' },
                                {name: 'CNPJ', key: 'cnpj', type: 'text' },
                                {name: 'E-mail', key: 'email', type: 'text' }
                            ]}
                            filter={setFilters}
                        />
                    }

                    right={
                        <>
                            {/* <Button type={`secondary`} size={`small`} text={`Filtrar`} svg="filter" /> */}
                            <Button 
                                type={`primary`}
                                size={`small`}
                                text={`Cadastrar orçamento`}
                                svg="plus-white"
                                action={() => setShowCreateQuote(true)}
                            />
                        </>
                    }
                />
                <ul className="filter-tab">
                    <li><a href="#" className={tab === 'Todos' ? 'active' : ''} onClick={e => {
                        e.preventDefault();
                        setTab('Todos');
                    }}>Todos</a></li>
                    <li><a href="#" className={tab === 'Orçamento aprovado' ? 'active' : ''} onClick={e => {
                        e.preventDefault();
                        setTab('Orçamento aprovado');
                    }}>Orçamento aprovado</a></li>
                    <li><a href="#" className={tab === 'Aguardando orçamento' ? 'active' : ''} onClick={e => {
                        e.preventDefault();
                        setTab('Aguardando orçamento');
                    }}>Aguardando orçamento</a></li>
                    <li><a href="#" className={tab === 'Pendente de aprovação' ? 'active' : ''} onClick={e => {
                        e.preventDefault();
                        setTab('Pendente de aprovação');
                    }}>Pendente de aprovação</a></li>
                    <li><a href="#" className={tab === 'Não aprovado' ? 'active' : ''} onClick={e => {
                        e.preventDefault();
                        setTab('Não aprovado');
                    }}>Não aprovado</a></li>
                </ul>
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default QuoteList;