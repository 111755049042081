import React, { useState, useEffect } from "react";

import PanelTemplate from "../../templates/Panel";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import api from "../../services/api";
import { useParams } from "react-router-dom";
import Box from "../../components/Box";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import { renderError } from "../../helpers/errors";
import InputEditor from "../../components/InputEditor";
import { Loading } from "../../components/Loading";
import InputTextarea from "../../components/InputTextarea";
import { toast } from "react-toastify";

export const EmailTemplateEdit = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [emailTemplate, setEmailTemplate] = useState(null);

    const [type, setType] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');

    useEffect(() => {
        getEmailTemplate();
    }, []);

    const getEmailTemplate = () => {
        setLoading(true);

        api.get(`/email-template/${id}`).then(res => {
            let resource = res.data.resource;

            setEmailTemplate(resource);
            setType(resource.type);
            setSubject(resource.subject);
            setBody(resource.body);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updateTemplateEmail = () => {
        setLoading(true);

        api.put(`/email-template/${id}`, {
            type,
            subject,
            body
        }).then(res => {
            getEmailTemplate();
            toast.success('Template de e-mail atualizado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <PanelTemplate id="email-template-edit">
                <Box>
                    {!loading &&
                        <>
                            <Input label={`Assunto`} value={subject} change={setSubject} />
                            <InputTextarea label={`Corpo do e-mail`} value={body} change={setBody} rows={6} />
                            <h2>Instruções</h2>
                            <p>Você pode usar [nome_do_contato] para exibir o nome do contato.</p>
                        </>
                    }
                    {loading &&
                        <Loading />
                    }
                </Box>
                <PageFooter>
                    <Button
                        type={`primary`}
                        size={`small`}
                        svg={`save-white`}
                        text={`Salvar alterações`}
                        action={updateTemplateEmail}
                        loading={loading}
                    />
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default EmailTemplateEdit;