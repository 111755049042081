import React from "react";
import Input from "../../../components/Input";
import { convertIntToMoney } from "../../../helpers/conversions";

const OrderDetailTab = ({order}) => {
    return (
        <>
            <div className="row">
                <div className="col-3">
                    <Input label={`Código do pedido`} value={order?.code} disabled={true} />
                </div>
            </div>
            {order?.type === "PJ" &&
                <div className="row">
                    <div className="col-4">
                        <Input label={`CNPJ`} value={order?.customer_company_cnpj} disabled={true} />
                    </div>
                    <div className="col-4">
                        <Input label={`Razão soscial`} value={order?.customer_company_company_name} disabled={true} />
                    </div>
                    <div className="col-4">
                        <Input label={`Nome fantasia`} value={order?.customer_company_trade_name} disabled={true} />
                    </div>
                </div>
            }
            {order?.type === "PF" &&
                <div className="row">
                    <div className="col-4">
                        <Input label={`CPF`} value={order?.customer_cpf} disabled={true} />
                    </div>
                    <div className="col-4">
                        <Input label={`Nome`} value={order?.customer_name} disabled={true} />
                    </div>
                </div>
            }
            
            <div className="row">
                <div className="col-4">
                    <Input label={`Total`} value={`R$ ${convertIntToMoney(order?.total)}`} disabled={true} />
                </div>
            </div>
        </>
    );
} 

export default OrderDetailTab;