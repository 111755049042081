import React from 'react';
import { BrowserRouter, Navigate, Route, Routes as ReactRouterRoutes, useLocation } from "react-router-dom";

import isAuthenticated from '../services/auth';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import PasswordRecovery from '../pages/PasswordRecovery';

import ProductList from '../pages/Product/list';
import ProductEdit from '../pages/Product/edit';
import ProductCreate from '../pages/Product/create';
import ColorList from '../pages/Color/list';
import CategoryList from '../pages/Category/list';
import TagList from '../pages/Tag/list';
import CustomizationList from '../pages/Customization/list';
import CustomizationEdit from '../pages/Customization/edit';
import CompanyList from '../pages/Company/list';
import CompanyEdit from '../pages/Company/edit';
import CustomerList from '../pages/Customer/list';
import QuoteList from '../pages/Quote/list';
import QuoteEdit from '../pages/Quote/edit';
import OrderCreate from '../pages/Order/craete';
import OrderList from '../pages/Order/list';
import OrderEdit from '../pages/Order/edit';
import UserList from '../pages/User/list';
import Proposal from '../pages/Proposal';
import PageList from '../pages/Page';
import PageEdit from '../pages/Page/edit';
import BannerList from '../pages/Banner';
import LogoList from '../pages/Logo';
import TestimonialList from '../pages/Testimonial';
import Calculation from '../pages/Calculation';
import EmailTemplateEdit from '../pages/EmailTemplate/edit';
import EmailTemplateList from '../pages/EmailTemplate';
import { CustomerEdit } from '../pages/Customer/edit';
import StatusList from '../pages/Status/list';

const Routes = () => {
    const RequireAuth = ({children}) => {
        let location = useLocation();
        
        if(!isAuthenticated()){
            return <Navigate to="/login" state={{ from: location }} />;
        }

        return children;
    };


    return (
        <BrowserRouter>
            <ReactRouterRoutes>
                <Route exact path="/" element={isAuthenticated ? <Navigate to="/product/list" /> : <Navigate to="/login" /> } />
                <Route path="/login" element={<Login />} />
                <Route path="/password-recovery" element={<PasswordRecovery />} />

                <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />

                <Route path="/product/list" element={<RequireAuth><ProductList /></RequireAuth>} />
                <Route path="/product/edit/:id" element={<RequireAuth><ProductEdit /></RequireAuth>} />
                <Route path="/product/create" element={<RequireAuth><ProductCreate /></RequireAuth>} />
                
                <Route path="/color/list" element={<RequireAuth><ColorList /></RequireAuth>} />

                <Route path="/category/list" element={<RequireAuth><CategoryList /></RequireAuth>} />

                <Route path="/tag/list" element={<RequireAuth><TagList /></RequireAuth>} />

                <Route path="/status/list" element={<RequireAuth><StatusList /></RequireAuth>} />
                
                <Route path="/customization/list" element={<RequireAuth><CustomizationList /></RequireAuth>} />
                <Route path="/customization/edit/:id" element={<RequireAuth><CustomizationEdit /></RequireAuth>} />

                <Route path="/company/list" element={<RequireAuth><CompanyList /></RequireAuth>} />
                <Route path="/company/edit/:id" element={<RequireAuth><CompanyEdit /></RequireAuth>} />

                <Route path="/customer/list" element={<RequireAuth><CustomerList /></RequireAuth>} />
                <Route path="/customer/edit/:id" element={<RequireAuth><CustomerEdit /></RequireAuth>} />

                <Route path="/quote/list" element={<RequireAuth><QuoteList /></RequireAuth>} />
                <Route path="/quote/edit/:id" element={<RequireAuth><QuoteEdit /></RequireAuth>} />
                <Route path="/proposal/:id" element={<RequireAuth><Proposal /></RequireAuth>} />

                <Route path="/order/list" element={<RequireAuth><OrderList /></RequireAuth>} />
                <Route path="/order/create/:id" element={<RequireAuth><OrderCreate /></RequireAuth>} />
                <Route path="/order/edit/:id" element={<RequireAuth><OrderEdit /></RequireAuth>} />

                <Route path="/user/list" element={<RequireAuth><UserList /></RequireAuth>} />


                <Route path="/page/list" element={<RequireAuth><PageList /></RequireAuth>} />
                <Route path="/page/edit/:id" element={<RequireAuth><PageEdit /></RequireAuth>} />

                <Route path="/email-template/list" element={<RequireAuth><EmailTemplateList /></RequireAuth>} />
                <Route path="/email-template/edit/:id" element={<RequireAuth><EmailTemplateEdit /></RequireAuth>} />

                <Route path="/banner/list" element={<RequireAuth><BannerList /></RequireAuth>} />
                
                <Route path="/logo/list" element={<RequireAuth><LogoList /></RequireAuth>} />

                <Route path="/testimonial/list" element={<RequireAuth><TestimonialList /></RequireAuth>} />

                <Route path="/calculation" element={<RequireAuth><Calculation /></RequireAuth>} />
            </ReactRouterRoutes>
        </BrowserRouter>
    );
}

export default Routes;